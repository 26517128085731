import React, { useRef, useContext } from "react";
import { LotteryContext } from "../main/LotteryBox";
import metamaskLogo from "../../../images/metamask.svg";
import { useMetaMask } from "metamask-react";

function InputAddress() {
  const { wallet } = useContext(LotteryContext);
  const [address, setAddress] = wallet;

  const inputRef = useRef(null);
  const { account } = useMetaMask();

  const handleInputChange = (event, type) => {
    if (type === "address") {
      setAddress(event.target.value);
    }
  };

  const handleButtonClick = () => {
    inputRef.current.value = account;
    setAddress(account);
  };

  return (
    <div className="flex gap-[.75em] md:gap-[1em] lg:gap-[2em] items-center mb-[2em] sm:mb-[3em] lg:mb-[4em]">
      <img
        src={metamaskLogo}
        alt={"Metamask logo"}
        className="cursor-pointer w-[20px] h-[20px] md:w-[25px] md:h-[25px] lg:w-[33px] lg:h-[33px] hover:rotate-[-360deg] transition duration-500 ease-in-out"
        onClick={() => handleButtonClick()}
      />
      <input
        className="font-semibold rounded-3xl flex-1 w-full bg-[#393354] h-[27px] md:h-[32px] lg:h-[40px] placeholder:text-[#A49D9D] text-[#A49D9D] lg:text-[14px] text-[12px] placeholder:font-bold px-[25px] outline-none lg:placeholder:text-[14px] placeholder:text-[12px]"
        placeholder="Input your wallet address"
        ref={inputRef}
        onChange={(e) => handleInputChange(e, "address")}
      />
    </div>
  );
}

export default InputAddress;
