import React, { useRef, useContext } from "react";
import { LotteryContext } from "../main/LotteryBox";

function NumOfTokens() {
  const { distributionMethod, numTokens } = useContext(LotteryContext);
  const [chosenDistributionMethod, setChosenDistributionMethod] =
    distributionMethod;
  const [numOfTokens, setNumOfTokens] = numTokens;

  const numOfTokensRef = useRef(null);

  const handleInputChange = (event, type) => {
    if (type === "numOfTokens") {
      numOfTokensRef.current.value = numOfTokensRef.current.value.replace(
        /[^.,\d]/g,
        ""
      );
      setNumOfTokens(event.target.value);
    } else if (type === "percentageTokenSplit") {
      numOfTokensRef.current.value = numOfTokensRef.current.value.replace(
        /[^,\d]/g,
        ""
      );
      setNumOfTokens(event.target.value);
    }
  };

  return (
    <div className="flex flex-col gap-[1em] items-center">
      <p className="text-[#A49D9D] font-semibold w-[150px] sm400:w-[180px] md:w-[200px] lg:w-[270px] text-center text-[12px] md:text-[14px] lg:text-[16px]">
        {chosenDistributionMethod === "exact"
          ? "Number of tokens for each winner (seperated by comma)"
          : "Percentage token split (seperated by comma)"}
      </p>
      {chosenDistributionMethod === "exact" && (
        <div className="bg-[#393354] md:h-[30px] h-[20px] lg:h-[40px] rounded-3xl flex items-center justify-center p-[15px] w-[155px] md:w-[240px] lg:w-[280px]">
          <input
            onChange={(e) => handleInputChange(e, "numOfTokens")}
            ref={numOfTokensRef}
            className="text-[#A49D9D] font-semibold w-[200px] bg-transparent text-center outline-none"
          />
        </div>
      )}
      {chosenDistributionMethod === "percentage" && (
        <div className="bg-[#393354] md:h-[30px] h-[20px] lg:h-[40px] rounded-3xl flex items-center justify-center p-[15px] w-[155px] md:w-[240px] lg:w-[280px]">
          <input
            onChange={(e) => handleInputChange(e, "percentageTokenSplit")}
            ref={numOfTokensRef}
            className="text-[#A49D9D] font-semibold w-[200px] bg-transparent text-center outline-none"
          />
        </div>
      )}
    </div>
  );
}

export default NumOfTokens;
