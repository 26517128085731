import React, { useState, useEffect, useContext } from "react";
import { LotteryContext } from "./LotteryBox";
import Checkbox from "@mui/material/Checkbox";
import polygonLogo from "../../../images/polygonLogo.svg";
import ethereumLogo from "../../../images/ethereum.png";
import MaticChosen from "./MaticChosen";
import ERC20Chosen from "./ERC20Chosen";
import ERC721Chosen from "./ERC721Chosen";
import { useMetaMask } from "metamask-react";
import { toast } from "react-toastify";

function SelectAssets({ setErc721Submitted }) {
  const { assetChoice, distributionMethod, erc20 } = useContext(LotteryContext);

  const [erc20Token, setErc20Token] = erc20;
  const [chosenDistributionMethod, setChosenDistributionMethod] =
    distributionMethod;
  const [assetChosen, setAssetChosen] = assetChoice;
  const [checkedAssetType, setCheckedAssetType] = useState();
  const [checkedDistributionType, setCheckedDistributionType] = useState();
  const [checkedTokenType, setCheckedTokenType] = useState();
  const { chainId } = useMetaMask();

  const setAssetType = (name) => {
    if (
      process.env.REACT_APP_DEV_ENV === "production" &&
      name === "MATIC" &&
      chainId === "0x89"
    ) {
      if (checkedAssetType === "MATIC") {
        setCheckedAssetType("");
      } else {
        setCheckedAssetType(name);
        setAssetChosen(name);
      }
    }
    if (
      process.env.REACT_APP_DEV_ENV === "production" &&
      name === "ERC20" &&
      chainId === "0x1"
    ) {
      if (checkedAssetType === "ERC20") {
        setCheckedAssetType("");
      } else {
        setCheckedAssetType(name);
        setAssetChosen(name);
      }
    }
    if (
      process.env.REACT_APP_DEV_ENV === "production" &&
      name === "ERC721" &&
      chainId === "0x1"
    ) {
      if (checkedAssetType === "ERC721") {
        setCheckedAssetType("");
      } else {
        setCheckedAssetType(name);
        setAssetChosen(name);
      }
    }

    if (process.env.REACT_APP_DEV_ENV === "production") {
      if (
        (name === "MATIC" && chainId !== "0x89") ||
        (name === "ERC20" && chainId !== "0x1") ||
        (name === "ERC721" && chainId !== "0x1")
      ) {
        toast.error(
          `Please switch your chain to ${
            name === "MATIC" ? "Polygon" : "Ethereum"
          }!`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    }
    ////////////////////////////////////////////////////////////////
    if (
      process.env.REACT_APP_DEV_ENV === "development" &&
      name === "MATIC" &&
      chainId === "0x13881"
    ) {
      if (checkedAssetType === "MATIC") {
        setCheckedAssetType("");
      } else {
        setCheckedAssetType(name);
        setAssetChosen(name);
      }
    }
    if (
      process.env.REACT_APP_DEV_ENV === "development" &&
      name === "ERC20" &&
      chainId === "0x5"
    ) {
      if (checkedAssetType === "ERC20") {
        setCheckedAssetType("");
      } else {
        setCheckedAssetType(name);
        setAssetChosen(name);
      }
    }
    if (
      process.env.REACT_APP_DEV_ENV === "development" &&
      name === "ERC721" &&
      chainId === "0x5"
    ) {
      if (checkedAssetType === "ERC721") {
        setCheckedAssetType("");
      } else {
        setCheckedAssetType(name);
        setAssetChosen(name);
      }
    }

    if (process.env.REACT_APP_DEV_ENV === "development") {
      if (
        (name === "MATIC" && chainId !== "0x13881") ||
        (name === "ERC20" && chainId !== "0x5") ||
        (name === "ERC721" && chainId !== "0x5")
      ) {
        toast.error(
          `Please switch your chain to ${
            name === "MATIC" ? "Mumbai" : "Goerli"
          }!`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    }
  };

  const setDistributionType = (type) => {
    if (type === "exact") {
      if (checkedDistributionType === "exact") {
        setCheckedDistributionType("");
      } else {
        setCheckedDistributionType(type);
        setChosenDistributionMethod(type);
      }
    }
    if (type === "percentage") {
      if (checkedDistributionType === "percentage") {
        setCheckedDistributionType("");
      } else {
        setCheckedDistributionType(type);
        setChosenDistributionMethod(type);
      }
    }
  };

  const setTokenType = (token) => {
    if (token === "Ethereum") {
      if (checkedTokenType === "Ethereum") {
        setCheckedTokenType("");
      } else {
        setCheckedTokenType(token);
        setErc20Token(token);
      }
    }
    if (token === "USDT") {
      if (checkedTokenType === "USDT") {
        setCheckedTokenType("");
      } else {
        setCheckedTokenType(token);
        setErc20Token(token);
      }
    }
    if (token === "USDC") {
      if (checkedTokenType === "USDC") {
        setCheckedTokenType("");
      } else {
        setCheckedTokenType(token);
        setErc20Token(token);
      }
    }
  };

  const [isDesktop, setDesktop] = useState(window.innerWidth > 640);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 640);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="flex flex-col items-center font-inter">
      <h2 className="text-[14px] xxs:text-[16px] sm:text-[20px] md:text-[22px] lg:text-[24px] font-semibold mt-[2em] md:mt-[1em] lg:mt-[2em] text-center">
        Select assets for your Giveaway Contract
      </h2>
      <div className="w-full mt-[1em] lg:mt-[1.5em] md:mt-[1.3em]">
        <div className="w-full flex flex-wrap items-center justify-center ">
          <div className="flex items-center sm500:mr-[.5em] md:mr-[1em] mr-[1em]">
            <Checkbox
              size={`${isDesktop ? "medium" : "small"}`}
              onChange={() => setAssetType("MATIC")}
              checked={checkedAssetType === "MATIC"}
            />
            <img
              src={polygonLogo}
              alt={"Polygon Logo"}
              className="w-[16px] h-[16px] xxs:w-[18px] xxs:h-[18px] sm:w-[20px] sm:h-[20px] md:w-[22px] md:h-[22px] lg:w-[24px] lg:h-[24px] mr-[.3em]"
            />
            <p className="text-[10px] xxs:text-[12px] lg:text-[14px] font-bold text-[#8247E5]">
              Matic Token
            </p>
          </div>
          <div className="flex items-center mr-[1em] sm500:mr-[.5em] md:mr-[1em]">
            <Checkbox
              size={`${isDesktop ? "medium" : "small"}`}
              onChange={() => setAssetType("ERC20")}
              checked={checkedAssetType === "ERC20"}
            />
            <img
              src={ethereumLogo}
              alt={"Ethereum Logo"}
              className="w-[16px] h-[16px] xxs:w-[18px] xxs:h-[18px] sm:w-[20px] sm:h-[20px] md:w-[22px] md:h-[22px] lg:w-[24px] lg:h-[24px] mr-[.3em]"
            />
            <p className="text-[10px] xxs:text-[12px] lg:text-[14px] font-bold text-[#627EEA]">
              ERC20 Token
            </p>
          </div>
          <div className="flex items-center mr-[15px] sm500:mr-[.5em] md:mr-[15px]">
            <Checkbox
              size={`${isDesktop ? "medium" : "small"}`}
              onChange={() => setAssetType("ERC721")}
              checked={checkedAssetType === "ERC721"}
            />
            <img
              src={ethereumLogo}
              alt={"Ethereum Logo"}
              className="w-[16px] h-[16px] xxs:w-[18px] xxs:h-[18px] sm:w-[20px] sm:h-[20px] md:w-[22px] md:h-[22px] lg:w-[24px] lg:h-[24px] xxs:ml-[.5em] mr-[.3em]"
            />
            <p className="text-[10px] xxs:text-[12px] md:text-[14px] font-bold text-[#627EEA]">
              ERC721 Token
            </p>
          </div>
        </div>
      </div>
      {checkedAssetType === "MATIC" && (
        <MaticChosen
          setDistributionType={setDistributionType}
          checkedDistributionType={checkedDistributionType}
          isDesktop={isDesktop}
        />
      )}
      {checkedAssetType === "ERC20" && (
        <ERC20Chosen
          setDistributionType={setDistributionType}
          checkedDistributionType={checkedDistributionType}
          setTokenType={setTokenType}
          checkedTokenType={checkedTokenType}
          erc20Token={erc20Token}
          isDesktop={isDesktop}
        />
      )}
      {checkedAssetType === "ERC721" && <ERC721Chosen />}
    </div>
  );
}

export default SelectAssets;
