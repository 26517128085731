import React, { useRef, useContext } from "react";
import { LotteryContext } from "../main/LotteryBox";

import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

function GiveawayDuration() {
  const { duration } = useContext(LotteryContext);
  const [durationInHours, setDurationInHours] = duration;

  const durationRef = useRef(null);

  const handleWinnersChange = (type, input) => {
    if (input === "duration") {
      if (
        parseInt(durationRef.current.value) !== 1 &&
        type === "dec" &&
        parseInt(durationRef.current.value) !== 0
      ) {
        if (durationInHours === "") {
          setDurationInHours(1);
          durationRef.current.value = 1;
        } else {
          durationRef.current.value = parseInt(durationRef.current.value) - 1;
        }
      }
      if (type === "inc") {
        if (durationInHours === "") {
          setDurationInHours(1);
          durationRef.current.value = 1;
        } else {
          durationRef.current.value = parseInt(durationRef.current.value) + 1;
        }
      }
      setDurationInHours(parseInt(durationRef.current.value));
    }
  };

  const handleInputChange = (event) => {
    durationRef.current.value = durationRef.current.value.replace(/[^\d]/g, "");
    setDurationInHours(event.target.value);
  };
  return (
    <div className="flex flex-col gap-[1em] items-center  text-center sm400:mr-[2em] sm470:mr-[0] mr-[1em]">
      <p className="text-[#A49D9D]  font-semibold w-[115px] md:w-[135px] lg:w-[160px] text-[12px] md:text-[14px] lg:text-[16px]">
        Giveaway duration in hours
      </p>
      <div className="bg-[#393354] w-[130px] lg:w-[160px] md:h-[30px] h-[20px] lg:h-[40px] rounded-3xl flex items-center justify-between p-[15px]">
        <AiOutlineMinus
          onClick={() => handleWinnersChange("dec", "duration")}
          className="text-[#796FA8] text-[16px] md:text-[18px] lg:text-[20px] cursor-pointer"
        />
        <input
          ref={durationRef}
          defaultValue={1}
          onChange={(e) => handleInputChange(e, "duration")}
          className="text-[#A49D9D] font-semibold w-[65px] bg-transparent text-center outline-none lg:text-[16px] md:text-[14px] text-[12px]"
        />
        <AiOutlinePlus
          onClick={() => handleWinnersChange("inc", "duration")}
          className="text-[#796FA8] text-[16px] md:text-[18px] lg:text-[20px] cursor-pointer"
        />
      </div>
    </div>
  );
}

export default GiveawayDuration;
