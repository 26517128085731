import React, { useRef, useContext } from "react";
import { LotteryContext } from "../main/LotteryBox";

function TotalReward() {
  const { totalPercReward } = useContext(LotteryContext);
  const [totalPercentageReward, setTotalPercentageReward] = totalPercReward;

  const handleInputChange = (event) => {
    rewardRef.current.value = rewardRef.current.value.replace(/[^.\d]/g, "");
    setTotalPercentageReward(event.target.value);
  };

  const rewardRef = useRef(null);

  return (
    <div className={`flex flex-col gap-[1em] items-center text-center`}>
      <p className="text-[#A49D9D] font-semibold lg:w-[100px] w-[70px] md:w-[80px] text-[12px] md:text-[14px] lg:text-[16px]">
        Total Reward
      </p>
      <div className="bg-[#393354] w-[90px] md:w-[100px] lg:w-[120px] md:h-[30px] h-[20px] lg:h-[40px] rounded-3xl flex items-center justify-between p-[15px]">
        <input
          ref={rewardRef}
          onChange={(e) => handleInputChange(e)}
          className="text-[#A49D9D] font-semibold bg-transparent w-full outline-none text-center lg:text-[16px] md:text-[14px] text-[12px]"
        />
      </div>
    </div>
  );
}

export default TotalReward;
