import React, { useContext } from "react";
import { FormContext } from "../../main/ContractForm";
import DzinChat from "../DzinChat";
import BlockMessage from "../BlockMessage";

function YesSA() {
  const {
    creatorStart,
    chosenService,
    isThinking,
    stepThinking,
    agreementService,
    isInformedByEmail,
    buySupply,
    organizationPriv,
    suppName,
    street,
    streetNum,
    postal,
    cityBased,
    countryBased,
    oneOfSign,
    firstN,
    lastN,
    role,
    buyerPriv,
    buyerName,
    buyerStreet,
    buyerStreetNum,
    buyerPostal,
    buyerCityBased,
    buyerCountryBased,
    oneOfSignersSecond,
  } = useContext(FormContext);

  const [creatorStarted, setCreatorStarted] = creatorStart;
  const [serviceChoice, setServiceChoice] = chosenService;
  const [thinking, setThinking] = isThinking;
  const [thinkingStep, setThinkingStep] = stepThinking;
  const [serviceAgreement, setServiceAgreement] = agreementService;
  const [informEmail, setInformEmail] = isInformedByEmail;
  const [buyerSupplier, setBuyerSupplier] = buySupply;
  const [orgPrivate, setOrgPrivate] = organizationPriv;
  const [supplierName, setSupplierName] = suppName;
  const [streetName, setStreetName] = street;
  const [streetNumber, setStreetNumber] = streetNum;
  const [postalCode, setPostalCode] = postal;
  const [cityBase, setCityBase] = cityBased;
  const [countryBase, setCountryBase] = countryBased;
  const [oneOfSigners, setOneOfSigners] = oneOfSign;
  const [firstName, setFirstName] = firstN;
  const [lastName, setLastName] = lastN;
  const [roleInOrg, setRoleInOrg] = role;
  const [buyerPrivate, setBuyerPrivate] = buyerPriv;
  const [fullNameBuyer, setFullNameBuyer] = buyerName;
  const [buyerStreetName, setBuyerStreetName] = buyerStreet;
  const [buyerStreetNumber, setBuyerStreetNumber] = buyerStreetNum;
  const [buyerPostalCode, setBuyerPostalCode] = buyerPostal;
  const [buyerCity, setBuyerCity] = buyerCityBased;
  const [buyerCountry, setBuyerCountry] = buyerCountryBased;
  const [oneOfSignersTwo, setOneOfSignersTwo] = oneOfSignersSecond;

  return (
    <div>
      {creatorStarted &&
        serviceChoice === "Create a new contract" &&
        serviceAgreement === "Yes" &&
        thinkingStep >= 3 && (
          <BlockMessage
            buttons={true}
            contentArray={[
              "Are you the buyer or the supplier of the services?",
            ]}
            setButtonsFunc={setBuyerSupplier}
            buttonsValue={buyerSupplier}
            buttonsContent={["Buyer", "Supplier"]}
          />
        )}
      {creatorStarted &&
        serviceChoice === "Create a new contract" &&
        serviceAgreement === "Yes" &&
        buyerSupplier !== "" &&
        thinkingStep >= 4 && (
          <BlockMessage
            buttons={true}
            contentArray={[
              "Now I’ll need the contact details of all parties in the contract.",
              "Let’s start with the details for the supplier.",
              "Is the supplier an organization or a private person?",
            ]}
            setButtonsFunc={setOrgPrivate}
            buttonsValue={orgPrivate}
            buttonsContent={["Company/organization", "Private person"]}
          />
        )}
      {creatorStarted &&
        serviceChoice === "Create a new contract" &&
        serviceAgreement === "Yes" &&
        buyerSupplier !== "" &&
        orgPrivate !== "" &&
        thinkingStep >= 5 && (
          <div className="flex flex-col gap-[20px] mb-[20px]">
            {orgPrivate === "Private person" && (
              <DzinChat
                avatar={true}
                content={`${
                  buyerSupplier === "Supplier"
                    ? "What is your first name?"
                    : "What is the first name?"
                }`}
              />
            )}
            {orgPrivate === "Company/organization" && (
              <DzinChat
                avatar={true}
                content={
                  "What is the full name of the organization of the supplier?"
                }
              />
            )}
            <DzinChat
              avatar={false}
              input={true}
              inputContent={"Type here..."}
              inputType={"organizationName"}
              setInputFunc={setSupplierName}
              setThinking={setThinking}
              setThinkingStep={setThinkingStep}
            />
            {supplierName !== "" && thinkingStep >= 6 && (
              <BlockMessage
                input={true}
                contentArray={["What is the street name?"]}
                setInputFunc={setStreetName}
              />
            )}
            {streetName !== "" && thinkingStep >= 7 && (
              <BlockMessage
                input={true}
                contentArray={["What is the street number?"]}
                setInputFunc={setStreetNumber}
              />
            )}
            {streetNumber !== "" && thinkingStep >= 8 && (
              <BlockMessage
                input={true}
                contentArray={["What is the postal code?"]}
                setInputFunc={setPostalCode}
              />
            )}
            {postalCode !== "" && thinkingStep >= 9 && (
              <BlockMessage
                input={true}
                contentArray={["In which city is the signatory based?"]}
                setInputFunc={setCityBase}
              />
            )}
            {cityBase !== "" && thinkingStep >= 10 && (
              <BlockMessage
                input={true}
                contentArray={["In which country is the signatory based?"]}
                setInputFunc={setCountryBase}
              />
            )}
            {countryBase !== "" && thinkingStep >= 11 && (
              <BlockMessage
                buttons={true}
                contentArray={[
                  "Now I’ll need to know who will sign on behalf of the supplier.",
                  "Will you be one of the signatories of the agreement?",
                ]}
                setButtonsFunc={setOneOfSigners}
                buttonsValue={oneOfSigners}
                buttonsContent={["Yes", "No"]}
              />
            )}
            {thinkingStep >= 12 && oneOfSigners !== "" && (
              <div className="flex flex-col gap-[20px] mb-[20px]">
                <BlockMessage
                  input={true}
                  contentArray={["What is the first name?"]}
                  setInputFunc={setFirstName}
                />

                {firstName !== "" && thinkingStep >= 13 && (
                  <BlockMessage
                    input={true}
                    contentArray={["What is the last name?"]}
                    setInputFunc={setLastName}
                  />
                )}
                {lastName !== "" && thinkingStep >= 14 && (
                  <BlockMessage
                    input={true}
                    contentArray={["What is their role in the organization?"]}
                    setInputFunc={setRoleInOrg}
                  />
                )}
              </div>
            )}
            {thinkingStep >= 15 && roleInOrg !== "" && (
              <div className="flex flex-col gap-[20px] mb-[20px]">
                <BlockMessage
                  buttons={true}
                  contentArray={[
                    "Now let’s continue with the details of the buyer.",
                    "Is the buyer an organization or a private person?",
                  ]}
                  setButtonsFunc={setBuyerPrivate}
                  buttonsValue={buyerPrivate}
                  buttonsContent={["Company/organization", "Private person"]}
                />
                {buyerPrivate !== "" && thinkingStep >= 16 && (
                  <BlockMessage
                    input={true}
                    contentArray={[
                      "What is the full name of the organization of the buyer?",
                    ]}
                    setInputFunc={setFullNameBuyer}
                  />
                )}
                {fullNameBuyer !== "" && thinkingStep >= 17 && (
                  <BlockMessage
                    input={true}
                    contentArray={["What is the street name?"]}
                    setInputFunc={setBuyerStreetName}
                  />
                )}
                {buyerStreetName !== "" && thinkingStep >= 18 && (
                  <BlockMessage
                    input={true}
                    contentArray={["What is the street number?"]}
                    setInputFunc={setBuyerStreetNumber}
                  />
                )}
                {buyerStreetNumber !== "" && thinkingStep >= 19 && (
                  <BlockMessage
                    input={true}
                    contentArray={["What is the postal code?"]}
                    setInputFunc={setBuyerPostalCode}
                  />
                )}
                {buyerPostalCode !== "" && thinkingStep >= 20 && (
                  <BlockMessage
                    input={true}
                    contentArray={["In which city is the signatory based?"]}
                    setInputFunc={setBuyerCity}
                  />
                )}
                {buyerCity !== "" && thinkingStep >= 21 && (
                  <BlockMessage
                    input={true}
                    contentArray={["In which country is the signatory based?"]}
                    setInputFunc={setBuyerCountry}
                  />
                )}
                {buyerCountry !== "" && thinkingStep >= 22 && (
                  <BlockMessage
                    buttons={true}
                    contentArray={[
                      "Now I’ll need to know who will sign on behalf of the buyer?",
                      "Will you be one of the signatories of the agreement?",
                    ]}
                    setButtonsFunc={setOneOfSignersTwo}
                    buttonsValue={oneOfSignersTwo}
                    buttonsContent={["Yes", "No"]}
                  />
                )}
                {oneOfSignersTwo !== "" && thinkingStep >= 23 && (
                  <BlockMessage
                    input={true}
                    contentArray={[
                      "Let's start with your contact details for the contract.",
                      "What is your first name?",
                    ]}
                    setInputFunc={setBuyerCountry}
                  />
                )}
              </div>
            )}
          </div>
        )}
    </div>
  );
}

export default YesSA;
