import React, { useContext } from "react";
import { LotteryContext } from "./LotteryBox";

function FinishScreen() {
  const {
    distributionMethod,
    nfts,
    erc20,
    submit,
    assetChoice,
    submitInfoSet,
    wallet,
    finished,
    setInputs,
    summaryGiveaway,
    setTwitterConfig,
    start,
    duration,
    finishTheLottery,
    numTokens,
    winners,
  } = useContext(LotteryContext);

  const [assetChosen, setAssetChosen] = assetChoice;
  const [chosenDistributionMethod, setChosenDistributionMethod] =
    distributionMethod;
  const [nftsArray, setNftsArray] = nfts;
  const [erc20Token, setErc20Token] = erc20;
  const [setErc20Submitted, setMaticSubmitted, setErc721Submitted] = submit;
  const [setMaticInfoSubmit, setErc20InfoSubmit, setErc721InfoSubmit] =
    submitInfoSet;
  const [address, setAddress] = wallet;
  const [finishTwitter, setFinishTwitter] = finished;
  const [
    setInputRetweet,
    setInputLike,
    setInputContent,
    setInputTwitterHandle,
    setWalletPost,
  ] = setInputs;
  const [confirmGiveawaySummary, setConfirmGiveawaySummary] = summaryGiveaway;
  const [setRetweet, setLike, setContent, setTwitterHandle] = setTwitterConfig;
  const [getStarted, setGetStarted] = start;
  const [durationInHours, setDurationInHours] = duration;
  const [finishLottery, setFinishLottery] = finishTheLottery;
  const [numOfTokens, setNumOfTokens] = numTokens;
  const [numOfWinners, setNumOfWinners] = winners;

  const handleFinish = () => {
    setGetStarted(false);
    setAddress("");
    setInputRetweet("");
    setInputLike("");
    setWalletPost("");
    setInputContent("");
    setInputTwitterHandle("");
    setChosenDistributionMethod("");
    setAssetChosen("");
    setErc20Token("");
    setDurationInHours(1);
    setNumOfWinners(1);
    setNumOfTokens("");
    setNftsArray([]);
    setRetweet(false);
    setFinishLottery(false);
    setLike(false);
    setContent(false);
    setTwitterHandle(false);
    setMaticInfoSubmit(false);
    setErc20InfoSubmit(false);
    setErc721InfoSubmit(false);
    setConfirmGiveawaySummary(false);
    setFinishTwitter(false);
    setErc721Submitted(false);
    setMaticSubmitted(false);
    setErc20Submitted(false);
  };

  return (
    <div className="h-full flex justify-center items-center flex-col gap-[4em] lg:gap-[6em] px-[20px]">
      <h1 className="text-[#A49D9D] text-[22px] sm:text-[24px] md:text-[26px] lg:text-[28px] text-center font-bold md:hidden">
        Giveaway contract has
        <br /> been created!
      </h1>
      <h1 className="text-[#A49D9D] text-[22px] sm:text-[24px] md:text-[26px] lg:text-[28px] text-center font-bold hidden md:block">
        Giveaway contract has been created!
      </h1>
      <button
        onClick={() => handleFinish()}
        className="hover:text-[#b6abf4] hover:from-[#4a3f8b] hover:to-[#4230aa] hover:via-[#6054a3] text-[16px] sm:text-[18px] md:text-[20px] lg:text-[22px] font-semibold font-inter text-[#BDB2FF] bg-gradient-to-b from-[#493D8D] via-[#493D8D] to-[#311da3] py-[6px] px-[30px]  sm:px-[35px] md:py-[8px] md:px-[40px] rounded-md"
      >
        GO BACK
      </button>
    </div>
  );
}

export default FinishScreen;
