import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import TopContainer from "../parts/TopContainer";
import InputAddress from "../parts/InputAddress";
import GiveawayDuration from "../parts/GiveawayDuration";
import SubmitButton from "../parts/SubmitButton";
import ScrollNfts from "../parts/ScrollNfts";

function ERC721Specifics() {
  return (
    <div className="flex flex-col font-inter p-[20px] md:p-[25px] lg:p-[30px]">
      <TopContainer />
      <InputAddress />
      <div className="mb-[3em] sm:mb-[5em] md:mb-[3em] sm600:hidden">
        <ScrollMenu>
          <ScrollNfts />
        </ScrollMenu>
        <div className="mt-[0.8em] ml-[1em] sm400:ml-[2em] sm470:ml-[0] sm470:mt-[2em] sm:mt-[2em] md:mt-[.8em]">
          <GiveawayDuration />
        </div>
      </div>
      <div className="hidden sm600:flex lg:hidden sm600:mb-[1.5em]">
        <ScrollNfts />
        <GiveawayDuration />
      </div>
      <div className="lg:flex w-full items-center justify-between mb-[5em] hidden">
        <ScrollNfts />
        <GiveawayDuration />
      </div>
      <div className="flex justify-center mt-[2.5em] xxs:mt-[-2em] sm600:mt-[2em] mb-[4em]">
        <SubmitButton type={"erc721"} />
      </div>
    </div>
  );
}

export default ERC721Specifics;
