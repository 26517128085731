import React, { useState, useEffect } from "react";
import TopContainer from "../parts/TopContainer";
import {
  AiOutlineRetweet,
  AiFillHeart,
  AiOutlineTwitter,
} from "react-icons/ai";
import { RiUserFollowFill } from "react-icons/ri";
import FinishButton from "../parts/FinishButton";
import { BiWallet } from "react-icons/bi";
import MoonLoader from "react-spinners/MoonLoader";
function TwitterSummary({
  inputRetweet,
  retweet,
  like,
  content,
  twitterHandle,
  inputTwitterHandle,
  inputLike,
  inputContent,
  walletPost,
}) {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 400);
  const [transactionPending, setTransactionPending] = useState(false);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 400);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <div className="flex flex-col font-inter p-[30px] h-full">
      {!transactionPending && (
        <div>
          <TopContainer />
          <h2 className="font-bold text-[14px] sm500:text-[16px] md:text-[18px] text-center mb-[1.5em] md:mb-[2em] lg:mb-[3em]">
            Twitter Summary
          </h2>
          <div className="flex flex-col gap-[1em] lg:gap-[2em]">
            <div className="flex gap-[1em] md:gap-[2em] items-center">
              <BiWallet className="text-[#669AFF] lg:h-[40px] lg:w-[40px] md:w-[35px] md:h-[35px] sm:w-[30px] sm:h-[30px] w-[25px] h-[25px]" />
              <p className="hidden sm:block text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                Twitter post address collecting wallets
              </p>
              <div className="hidden sm:block bg-[#393354] text-center flex-1 rounded-2xl text-[10px] sm500:text-[12px] text-[#A49D9D] sm500:h-[22px] max-w-[500px] md:text-[14px] md:h-[26px] lg:h-[30px] lg:text-[16px]">
                <a href={inputRetweet}>{walletPost.slice(0, 45) + "..."}</a>
              </div>
              <p className="text-[12px] sm:hidden sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                Wallets
              </p>
              <div className="bg-[#393354] sm:hidden text-center flex-1 rounded-2xl text-[10px] sm500:text-[12px] text-[#A49D9D] sm500:h-[22px] max-w-[500px] md:text-[14px] md:h-[26px] lg:h-[30px] lg:text-[16px]">
                <a href={inputRetweet}>
                  {isDesktop
                    ? walletPost.slice(0, 30) + "..."
                    : walletPost.slice(0, 20) + "..."}
                </a>
              </div>
            </div>
            {retweet && (
              <div className="flex gap-[1em] md:gap-[2em] items-center">
                <AiOutlineRetweet className="text-[#669AFF] lg:h-[40px] lg:w-[40px] md:w-[35px] md:h-[35px] sm:w-[30px] sm:h-[30px] w-[25px] h-[25px]" />
                <p className="hidden sm:block text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                  Twitter post address to retweet
                </p>
                <div className="hidden sm:block bg-[#393354] text-center flex-1 rounded-2xl text-[10px] sm500:text-[12px] text-[#A49D9D] sm500:h-[22px] max-w-[500px] md:text-[14px] md:h-[26px] lg:h-[30px] lg:text-[16px]">
                  <a href={inputRetweet}>{inputRetweet.slice(0, 45) + "..."}</a>
                </div>
                <p className="text-[12px] sm:hidden sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                  Retweet
                </p>
                <div className="bg-[#393354] sm:hidden text-center flex-1 rounded-2xl text-[10px] sm500:text-[12px] text-[#A49D9D] sm500:h-[22px] max-w-[500px] md:text-[14px] md:h-[26px] lg:h-[30px] lg:text-[16px]">
                  <a href={inputRetweet}>
                    {isDesktop
                      ? inputRetweet.slice(0, 30) + "..."
                      : inputRetweet.slice(0, 20) + "..."}
                  </a>
                </div>
              </div>
            )}
            {like && (
              <div className="flex gap-[1em] md:gap-[2em] items-center">
                <AiFillHeart className="text-[red] lg:h-[40px] lg:w-[40px] md:w-[35px] md:h-[35px] sm:w-[30px] sm:h-[30px] w-[25px] h-[25px]" />
                <p className="sm:block hidden text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                  Twitter post address to like
                </p>
                <div className="sm:block hidden bg-[#393354] text-center flex-1 rounded-2xl text-[10px] sm500:text-[12px] text-[#A49D9D] sm500:h-[22px] max-w-[500px] md:text-[14px] md:h-[26px] lg:h-[30px] lg:text-[16px]">
                  <a href={inputLike}>{inputLike.slice(0, 45) + "..."}</a>
                </div>
                <p className="sm:hidden text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                  Like Post
                </p>
                <div className="sm:hidden bg-[#393354] text-center flex-1 rounded-2xl text-[10px] sm500:text-[12px] text-[#A49D9D] sm500:h-[22px] max-w-[500px] md:text-[14px] md:h-[26px] lg:h-[30px] lg:text-[16px]">
                  <a href={inputLike}>
                    {isDesktop
                      ? inputLike.slice(0, 30) + "..."
                      : inputLike.slice(0, 20) + "..."}
                  </a>
                </div>
              </div>
            )}
            {content && (
              <div className="flex gap-[1em] md:gap-[2em] items-center">
                <AiOutlineTwitter className="text-[#669AFF] lg:h-[40px] lg:w-[40px] md:w-[35px] md:h-[35px] sm:w-[30px] sm:h-[30px] w-[25px] h-[25px]" />
                <p className="sm:block hidden text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                  Tweet Content
                </p>
                <div className="sm:block hidden bg-[#393354] text-center flex-1 rounded-2xl text-[10px] sm500:text-[12px] text-[#A49D9D] sm500:h-[22px] max-w-[500px] md:text-[14px] md:h-[26px] lg:h-[30px] lg:text-[16px]">
                  <a href={inputContent}>{inputContent.slice(0, 45) + "..."}</a>
                </div>
                <p className="sm:hidden text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                  Content
                </p>
                <div className="sm:hidden bg-[#393354] text-center flex-1 rounded-2xl text-[10px] sm500:text-[12px] text-[#A49D9D] sm500:h-[22px] max-w-[500px] md:text-[14px] md:h-[26px] lg:h-[30px] lg:text-[16px]">
                  <a href={inputLike}>
                    {isDesktop
                      ? inputContent.slice(0, 30) + "..."
                      : inputContent.slice(0, 20) + "..."}
                  </a>
                </div>
              </div>
            )}
            {twitterHandle && (
              <div className="flex gap-[1em] md:gap-[2em] items-center">
                <RiUserFollowFill className="text-[#669AFF] lg:h-[40px] lg:w-[40px] md:w-[35px] md:h-[35px] sm:w-[30px] sm:h-[30px] w-[25px] h-[25px]" />
                <p className="sm:block hidden text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                  Twitter Handle
                </p>
                <div className="sm:block hidden bg-[#393354] text-center flex-1 rounded-2xl text-[10px] sm500:text-[12px] text-[#A49D9D] sm500:h-[22px] max-w-[500px] md:text-[14px] md:h-[26px] lg:h-[30px] lg:text-[16px]">
                  <a href={inputContent}>
                    {inputTwitterHandle.length > 45
                      ? inputTwitterHandle.slice(0, 45) + "..."
                      : inputTwitterHandle.slice(0, 45)}
                  </a>
                </div>
                <p className="sm:hidden text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                  Handle
                </p>
                <div className="sm:hidden bg-[#393354] text-center flex-1 rounded-2xl text-[10px] sm500:text-[12px] text-[#A49D9D] sm500:h-[22px] max-w-[500px] md:text-[14px] md:h-[26px] lg:h-[30px] lg:text-[16px]">
                  <a href={inputLike}>
                    {isDesktop
                      ? inputTwitterHandle > 30
                        ? inputTwitterHandle.slice(0, 30) + "..."
                        : inputTwitterHandle.slice(0, 30)
                      : inputTwitterHandle.slice(0, 20) + "..."}
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className="lg:mt-[-1em]">
            <FinishButton setTransactionPending={setTransactionPending} />
          </div>
        </div>
      )}
      {transactionPending && (
        <div className="w-full h-full flex flex-col gap-[3em] justify-center items-center">
          <h1 className="text-[#A49D9D] text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] font-bold">
            Transaction pending...
          </h1>
          <MoonLoader size={46} color={"#ffff"} />
        </div>
      )}
    </div>
  );
}

export default TwitterSummary;
