import { useState, useEffect } from "react";
import { Alchemy, Network } from "alchemy-sdk";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";

function useFetchNfts(account) {
  const [allNfts, setAllNfts] = useState([]);

  const { promiseInProgress } = usePromiseTracker();

  const config =
    process.env.REACT_APP_DEV_ENV === "production"
      ? {
          apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
          network: Network.ETH_MAINNET,
        }
      : {
          apiKey: process.env.REACT_APP_ALCHEMY_API_KEY_DEV,
          network: Network.ETH_GOERLI,
        };

  const alchemy = new Alchemy(config);

  const fetchNfts = async () => {
    const urls = [];

    const nfts = await alchemy.nft.getNftsForOwner(account);

    nfts.ownedNfts.forEach((nft) => {
      let tokenType = nft.tokenType;
      if (tokenType === "ERC721") {
        if (nft.rawMetadata.image.slice(0, 7) === "ipfs://") {
          urls.push([
            nft,
            "https://ipfs.io/ipfs/" + nft.rawMetadata.image.slice(7),
          ]);
        } else {
          urls.push([nft, nft.rawMetadata.image]);
        }
      }
    });
    setAllNfts(urls);
  };

  useEffect(() => {
    trackPromise(fetchNfts());
  }, []);

  return { allNfts, promiseInProgress };
}

export default useFetchNfts;
