import React from "react";
import vaiotLogo from "../images/vaiotLogo.svg";
import ConnectButton from "./Lottery/main/ConnectButton";

function Navbar({
  isLottery,
  setIsLottery,
  isContractCreator,
  setIsContractCreator,
}) {
  const handleChange = (type) => {
    if (type === "lottery") {
      setIsContractCreator(false);
      setIsLottery(true);
    } else if (type === "contractCreator") {
      setIsLottery(false);
      setIsContractCreator(true);
    }
  };

  return (
    <div className="border-b-[0.5px] border-black border-opacity-20">
      <div className="px-[10px] py-[20px] sm:px-[20px] sm:py-[20px] flex justify-between ">
        <div className="flex justify-center items-center gap-[10px] md800:gap-[15px] lg:gap-[25px]">
          <a href="https://vaiot.ai/en" className="my-auto">
            <img
              href="https://vaiot.ai/en"
              className="transition ease-in-out hover:rotate-[-8deg] w-[80px] sm:w-[100px] duration-500 cursor-pointer"
              src={vaiotLogo}
            />
          </a>
          <div className="flex gap-[20px] items-center px-[10px] text-[12px] font-inter hidden sm600:flex md800:text-[14px] lg:text-[16px]">
            <p
              onClick={() => handleChange("lottery")}
              className={`${
                isLottery ? "font-bold" : ""
              } text-[#A49D9D] cursor-pointer`}
            >
              Lottery Creator
            </p>
            {/* <p
              onClick={() => handleChange("contractCreator")}
              className={`${
                isContractCreator ? "font-bold" : ""
              } text-[#A49D9D] cursor-pointer`}
            >
              Intelligent Contract Creator
            </p> */}
          </div>
        </div>
        <ConnectButton />
      </div>
      <div className="flex gap-[20px] items-center px-[10px] pb-[15px] text-[12px] font-inter sm600:hidden">
        <p
          onClick={() => handleChange("lottery")}
          className={`${
            isLottery ? "font-bold" : ""
          } text-[#A49D9D] cursor-pointer`}
        >
          Lottery Creator
        </p>
        {/* <p
          onClick={() => handleChange("contractCreator")}
          className={`${
            isContractCreator ? "font-bold" : ""
          } text-[#A49D9D] cursor-pointer`}
        >
          Intelligent Contract Creator
        </p> */}
      </div>
    </div>
  );
}

export default Navbar;
