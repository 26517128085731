import React, { useContext } from "react";
import { LotteryContext } from "./LotteryBox";

import Checkbox from "@mui/material/Checkbox";
import { GoAlert } from "react-icons/go";
import { toast } from "react-toastify";
import { useMetaMask } from "metamask-react";

function MaticChosen({
  setDistributionType,
  checkedDistributionType,
  isDesktop,
}) {
  const { chainId } = useMetaMask();
  const { submit } = useContext(LotteryContext);
  const [setErc20Submitted, setMaticSubmitted, setErc721Submitted] = submit;

  const handleMaticChosen = () => {
    if (
      (process.env.REACT_APP_DEV_ENV === "production" && chainId === "0x89") ||
      (process.env.REACT_APP_DEV_ENV === "development" && chainId === "0x13881")
    ) {
      if (
        checkedDistributionType === "exact" ||
        checkedDistributionType === "percentage"
      ) {
        setErc20Submitted(false);
        setErc721Submitted(false);
        setMaticSubmitted(true);
      } else {
        toast.error("Please choose distribution method!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(
        `Please switch your chain to ${
          process.env.REACT_APP_DEV_ENV === "production"
            ? "Polygon!"
            : "Mumbai Testnet!"
        }`,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  return (
    <div className="flex flex-col w-full">
      <h2 className="text-[14px] xxs:text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px] font-semibold mt-[1em] md:mt-[1.5em] mb-[.3em] md:mb-[1em] text-center">
        Select reward distribution method
      </h2>
      <div className="flex gap-[1em] sm400:gap-[3em] items-center justify-center mt-[1em] md:mt-[.5em] lg:mt-[1em]">
        <div className="flex items-center ">
          <Checkbox
            size={`${isDesktop ? "medium" : "small"}`}
            onChange={() => setDistributionType("exact")}
            checked={checkedDistributionType === "exact"}
          />
          <p className="text-[#8247E5] font-bold w-[85px] xxs:w-[110px] text-center text-[10px] xxs:text-[12px] md:text-[14px]">
            Exact number of tokens
          </p>
        </div>
        <div className="flex items-center ">
          <Checkbox
            size={`${isDesktop ? "medium" : "small"}`}
            onChange={() => setDistributionType("percentage")}
            checked={checkedDistributionType === "percentage"}
          />
          <p className="text-[#8247E5] font-bold w-[80px] xxs:w-[110px] text-center text-[10px] xxs:text-[12px] md:text-[14px]">
            Percentage token split
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-[1em] mb-[1em] sm:mb-[2em] sm:mt-[2em] md:mt-[2em] lg:mt-[4em] md:mb-[1em]">
        <button
          className="bg-[#5A5574] hover:text-[#d8d5d5] px-[30px] rounded-xl py-[8px] font-semibold text-[12px] md:text-[14px] lg:text-[16px]"
          onClick={() => handleMaticChosen()}
        >
          SUBMIT
        </button>
      </div>
      <div className="w-full flex flex-col items-start ml-[1.5em] md:ml-[1em] sm500:mt-[1em] lg:mt-[5em]">
        <div className="flex justify-center items-center gap-[1em] mb-[1em] max-w-[250px] xxs:max-w-[300px] sm500:max-w-[400px] sm:max-w-[400px]">
          <GoAlert className="text-[red] text-[9px] sm500:text-[10px] md:text-[14px]" />
          <p className="text-[#FF2F2F] text-[7px] sm500:text-[8px] md:text-[10px] font-semibold">
            "Exact number of tokens" - specify how many tokens each winner will
            get
          </p>
        </div>
        <div className="flex justify-center items-center gap-[1em] max-w-[250px] xxs:max-w-[300px] sm500:max-w-[400px] sm:max-w-[400px]">
          <GoAlert className="text-[red] text-[9px] sm500:text-[10px] md:text-[14px]" />
          <p className="text-[#FF2F2F] text-[7px] sm500:text-[8px]  md:text-[10px] font-semibold">
            "Percentage token split" - specify the % of tokens from the reward
            pool that each winner will get
          </p>
        </div>
      </div>
    </div>
  );
}

export default MaticChosen;
