import React, { useContext } from "react";
import { LotteryContext } from "./LotteryBox";
import Checkbox from "@mui/material/Checkbox";
import { GoAlert } from "react-icons/go";
import ethLogo from "../../../images/ethLogoTwo.svg";
import usdtLogo from "../../../images/usdtLogo.svg";
import usdcLogo from "../../../images/usdcLogo.svg";
import { toast } from "react-toastify";
import { useMetaMask } from "metamask-react";

function ERC20Chosen({
  setDistributionType,
  checkedDistributionType,
  checkedTokenType,
  setTokenType,
  erc20Token,
  isDesktop,
}) {
  const { submit } = useContext(LotteryContext);

  const [setErc20Submitted, setMaticSubmitted, setErc721Submitted] = submit;

  const { chainId } = useMetaMask();

  const handleErc20Choice = () => {
    if (
      (chainId === "0x1" && process.env.REACT_APP_DEV_ENV === "production") ||
      (chainId === "0x5" && process.env.REACT_APP_DEV_ENV === "development")
    ) {
      if (
        checkedDistributionType === "exact" ||
        checkedDistributionType === "percentage"
      ) {
        if (
          erc20Token === "Ethereum" ||
          erc20Token === "USDC" ||
          erc20Token === "USDT"
        ) {
          setMaticSubmitted(false);
          setErc721Submitted(false);
          setErc20Submitted(true);
        } else {
          toast.error("Please choose ERC20 token type!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } else {
        toast.error("Please choose distribution method!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(
        `Please switch your chain to ${
          process.env.REACT_APP_DEV_ENV === "production"
            ? "Ethereum"
            : "Goerli Testnet"
        }!`,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  const handleTokenChange = (token) => {
    if (process.env.REACT_APP_DEV_ENV === "production") {
      setTokenType(token);
    } else {
      if (token === "USDC" || token === "USDT") {
        toast.error(
          "USDT and USDC tokens are not available during beta testing!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      } else if (token === "Ethereum") {
        setTokenType("Ethereum");
      }
    }
  };

  return (
    <div className="flex flex-col w-full">
      <h2 className="text-[14px] sm:text-[18px] md:text-[20px] lg:text-[24px] font-semibold mt-[.7em] sm500:mt-[1.5em] text-center px-[5px]">
        Select the ERC20 token you wish to give away
      </h2>
      <div className="flex justify-center items-center gap-[.1em] lg:gap-[1em] mt-[.5em]">
        <div className="flex items-center ">
          <Checkbox
            size={`${isDesktop ? "medium" : "small"}`}
            onChange={() => handleTokenChange("Ethereum")}
            checked={checkedTokenType === "Ethereum"}
          />
          <img
            src={ethLogo}
            alt={"Ethereum Logo"}
            className="w-[16px] h-[16px] xxs:w-[18px] xxs:h-[18px] sm:w-[20px] sm:h-[20px] md:w-[22px] md:h-[22px] lg:w-[24px] lg:h-[24px]"
          />
          <p className="text-[#627EEA] font-bold ml-[.2em] text-center text-[10px] xxs:text-[12px] lg:text-[14px]">
            Ethereum
          </p>
        </div>
        <div className="flex items-center ">
          <Checkbox
            size={`${isDesktop ? "medium" : "small"}`}
            onChange={() => handleTokenChange("USDT")}
            checked={checkedTokenType === "USDT"}
          />
          <img
            src={usdtLogo}
            alt={"USDT Logo"}
            className="w-[16px] h-[16px] xxs:w-[18px] xxs:h-[18px] sm:w-[20px] sm:h-[20px] md:w-[22px] md:h-[22px] lg:w-[24px] lg:h-[24px]"
          />
          <p className="text-[#627EEA] font-bold ml-[.2em] text-center text-[10px] xxs:text-[12px] lg:text-[14px]">
            USDT
          </p>
        </div>
        <div className="flex items-center ">
          <Checkbox
            size={`${isDesktop ? "medium" : "small"}`}
            onChange={() => handleTokenChange("USDC")}
            checked={checkedTokenType === "USDC"}
          />
          <img
            src={usdcLogo}
            alt={"USDC Logo"}
            className="w-[16px] h-[16px] xxs:w-[18px] xxs:h-[18px] sm:w-[20px] sm:h-[20px] md:w-[22px] md:h-[22px] lg:w-[24px] lg:h-[24px]"
          />
          <p className="text-[#627EEA] font-bold ml-[.2em] text-center text-[10px] xxs:text-[12px] lg:text-[14px]">
            USDC
          </p>
        </div>
      </div>
      <h2 className="text-[14px] sm:text-[18px] md:text-[20px] lg:text-[24px] font-semibold mt-[1em] sm500:mt-[1.5em] text-center">
        Select reward distribution method
      </h2>
      <div className="flex gap-[.2em] lg:gap-[3em] items-center justify-center">
        <div className="flex items-center mt-[1em]">
          <Checkbox
            size={`${isDesktop ? "medium" : "small"}`}
            onChange={() => setDistributionType("exact")}
            checked={checkedDistributionType === "exact"}
          />
          <p className="text-[#8247E5] font-bold w-[90px] sm:w-[110px] text-center text-[10px] xxs:text-[12px] lg:text-[14px]">
            Exact number of tokens
          </p>
        </div>
        <div className="flex items-center mt-[1em]">
          <Checkbox
            size={`${isDesktop ? "medium" : "small"}`}
            onChange={() => setDistributionType("percentage")}
            checked={checkedDistributionType === "percentage"}
          />
          <p className="text-[#8247E5] font-bold w-[90px] sm:w-[110px] text-center text-[10px] xxs:text-[12px] lg:text-[14px]">
            Percentage token split
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-[1em] sm500:mt-[1.5em] mb-[1.8em]">
        <button
          className="bg-[#5A5574] hover:text-[#d8d5d5] px-[30px] rounded-xl py-[8px] font-semibold text-[10px] sm:text-[12px] lg:text-[14px]"
          onClick={() => handleErc20Choice()}
        >
          SUBMIT
        </button>
      </div>
      <div className="w-full flex flex-col items-start mt-[2.5em] sm500:mt-[3.5em] lg:mt-[1.5em] lg:ml-[1em]">
        <div className="flex justify-center items-center gap-[1em] mb-[1em]">
          <GoAlert className="text-[red] text-[14px]" />
          <p className="text-[#FF2F2F] text-[10px] font-bold">
            "Exact number of tokens" - specify how many tokens each winner will
            get
          </p>
        </div>
        <div className="flex justify-center items-center gap-[1em]">
          <GoAlert className="text-[red] text-[14px]" />
          <p className="text-[#FF2F2F] text-[10px] font-bold">
            "Percentage token split" - specify the % of tokens from the reward
            pool that each winner will get
          </p>
        </div>
      </div>
    </div>
  );
}

export default ERC20Chosen;
