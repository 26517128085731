import React, { useContext } from "react";
import DzinButtons from "./DzinButtons";
import DzinChat from "./DzinChat";
import { FormContext } from "../main/ContractForm";

function BlockMessage({
  contentArray,
  input,
  setInputFunc,
  buttons,
  buttonsContent,
  setButtonsFunc,
  buttonsValue,
}) {
  const { isThinking, stepThinking } = useContext(FormContext);

  const [thinking, setThinking] = isThinking;
  const [thinkingStep, setThinkingStep] = stepThinking;

  return (
    <>
      {buttons && (
        <div className="flex flex-col gap-[20px] mb-[20px]">
          <DzinChat avatar={true} content={contentArray[0]} />
          {contentArray[1] !== undefined && (
            <DzinChat avatar={false} content={contentArray[1]} />
          )}
          {contentArray[2] !== undefined && (
            <DzinChat avatar={false} content={contentArray[2]} />
          )}
          <DzinButtons
            content={buttonsContent}
            setFunc={setButtonsFunc}
            funcValue={buttonsValue}
            setThinking={setThinking}
            setThinkingStep={setThinkingStep}
          />
        </div>
      )}
      {input && (
        <div className="flex flex-col gap-[20px] mb-[20px]">
          <DzinChat avatar={true} content={contentArray[0]} />
          {contentArray[1] !== undefined && (
            <DzinChat avatar={false} content={contentArray[1]} />
          )}
          {contentArray[2] !== undefined && (
            <DzinChat avatar={false} content={contentArray[2]} />
          )}
          <DzinChat
            avatar={false}
            input={true}
            inputContent={"Type here..."}
            setInputFunc={setInputFunc}
            setThinking={setThinking}
            setThinkingStep={setThinkingStep}
          />
        </div>
      )}
    </>
  );
}

export default BlockMessage;
