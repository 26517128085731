import React, { useContext } from "react";
import { FormContext } from "../../main/ContractForm";
import DzinButtons from "../DzinButtons";
import DzinChat from "../DzinChat";

function NoSA() {
  const {
    creatorStart,
    chosenService,
    isThinking,
    stepThinking,
    agreementService,
    isInformedByEmail,
    emailAddress,
  } = useContext(FormContext);

  const [creatorStarted, setCreatorStarted] = creatorStart;
  const [serviceChoice, setServiceChoice] = chosenService;
  const [thinking, setThinking] = isThinking;
  const [thinkingStep, setThinkingStep] = stepThinking;
  const [serviceAgreement, setServiceAgreement] = agreementService;
  const [informEmail, setInformEmail] = isInformedByEmail;
  const [email, setEmail] = emailAddress;

  return (
    <div>
      {creatorStarted &&
        serviceChoice === "Create a new contract" &&
        serviceAgreement === "No" &&
        thinkingStep >= 3 && (
          <div>
            <DzinChat
              avatar={true}
              content={
                "Unfortunately, I can’t help you yet with other contract types."
              }
            />
            <DzinChat
              avatar={false}
              content={
                "We will continue to launch new contract types and features for EasyContracts."
              }
            />
            <DzinChat
              avatar={false}
              content={
                "Do you want us to keep you informed by email of VAIOT’s new releases?"
              }
              lastOne={true}
            />
            <DzinButtons
              content={["Yes", "No"]}
              setFunc={setInformEmail}
              funcValue={informEmail}
              setThinking={setThinking}
              setThinkingStep={setThinkingStep}
            />
          </div>
        )}
      {creatorStarted &&
        serviceChoice === "Create a new contract" &&
        serviceAgreement === "No" &&
        informEmail === "Yes" &&
        thinkingStep >= 4 && (
          <div>
            <DzinChat
              avatar={true}
              content={"Please input your email address below."}
            />
            <DzinChat
              avatar={false}
              input={true}
              inputContent={"Type here..."}
              setInputFunc={setEmail}
              inputType={"email"}
              setThinking={setThinking}
              setThinkingStep={setThinkingStep}
            />
          </div>
        )}
      {creatorStarted &&
        serviceChoice === "Create a new contract" &&
        serviceAgreement === "No" &&
        informEmail === "Yes" &&
        thinkingStep >= 5 &&
        email !== "" && (
          <div>
            <DzinChat
              avatar={true}
              lastOne={true}
              content={
                "Thank you very much for using our services. Follow us on twitter @VAIOT_LTD for upcoming updates regarding features for EasyContracts."
              }
            />
          </div>
        )}
      {creatorStarted &&
        serviceChoice === "Create a new contract" &&
        serviceAgreement === "No" &&
        informEmail === "No" &&
        thinkingStep >= 4 && (
          <div>
            <DzinChat
              avatar={true}
              lastOne={true}
              content={
                "Thank you very much for using our services. Follow us on twitter @VAIOT_LTD for upcoming updates regarding features for EasyContracts."
              }
            />
          </div>
        )}
    </div>
  );
}

export default NoSA;
