import React, { useEffect, useState, useContext } from "react";
import { LotteryContext } from "../main/LotteryBox";

function ScrollNfts() {
  const { nfts } = useContext(LotteryContext);
  const [nftsArray, setNftsArray] = nfts;
  const [urls, setUrls] = useState([]);

  let namesArray = ["First", "Second", "Third", "Fourth", "Fifth"];

  useEffect(() => {
    let tempUrls = [];
    nftsArray.forEach((nft) => {
      if (nft.rawMetadata.image.slice(0, 7) === "ipfs://") {
        tempUrls.push([
          nft,
          "https://ipfs.io/ipfs/" + nft.rawMetadata.image.slice(7),
        ]);
      } else {
        tempUrls.push([nft, nft.rawMetadata.image]);
      }
    });
    setUrls(tempUrls);
  }, []);

  const rewardNames = nftsArray.map((item, index) => (
    <div className="w-[73px] lg:w-[90px] text-center" key={index}>
      {namesArray[index] + " place award"}
    </div>
  ));

  const nftImages = urls.map((url) => (
    <img
      className="h-[75px] w-[70px] lg:h-[90px] lg:w-[90px]"
      src={url[1]}
      key={url}
    ></img>
  ));
  return (
    <div className="flex flex-col w-full mr-[5.2em]">
      <div className="flex gap-[27px] lg:gap-[30px] text-[#A49D9D] text-[12px] lg:text-[14px] font-semibold font-inter items-end justify-start mb-[4px] lg:mb-[10px]">
        {rewardNames}
      </div>
      <div
        className={`border-y-[3px] border-[#393354] h-[80px] ${
          urls.length <= 2 ? "w-[365px] lg:w-[450px]" : "w-[]"
        }  lg:h-[97px] flex gap-[30px]`}
      >
        {nftImages}
      </div>
    </div>
  );
}

export default ScrollNfts;
