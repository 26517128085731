import React, { useContext } from "react";
import { LotteryContext } from "../main/LotteryBox";
import usdtLogo from "../../../images/usdtLogo.svg";
import ethLogo from "../../../images/ethLogoTwo.svg";
import ethLogoTwo from "../../../images/ethereum.png";
import usdcLogo from "../../../images/usdcLogo.svg";
import maticLogo from "../../../images/polygonLogo.svg";
import { TiArrowBack } from "react-icons/ti";
import { BsCircleFill } from "react-icons/bs";

function TopContainer({ setRetweet, setLike, setContent, setTwitterHandle }) {
  const {
    distributionMethod,
    nfts,
    erc20,
    submit,
    assetChoice,
    submitInfoValue,
    submitInfoSet,
    wallet,
    finished,
    setInputs,
    summaryGiveaway,
  } = useContext(LotteryContext);
  const [assetChosen, setAssetChosen] = assetChoice;
  const [chosenDistributionMethod, setChosenDistributionMethod] =
    distributionMethod;
  const [nftsArray, setNftsArray] = nfts;
  const [erc20Token, setErc20Token] = erc20;
  const [setErc20Submitted, setMaticSubmitted, setErc721Submitted] = submit;
  const [maticInfoSubmit, erc20InfoSubmit, erc721InfoSubmit] = submitInfoValue;
  const [setMaticInfoSubmit, setErc20InfoSubmit, setErc721InfoSubmit] =
    submitInfoSet;
  const [address, setAddress] = wallet;
  const [finishTwitter, setFinishTwitter] = finished;
  const [
    setInputRetweet,
    setInputLike,
    setInputContent,
    setInputTwitterHandle,
    setWalletPost,
  ] = setInputs;
  const [confirmGiveawaySummary, setConfirmGiveawaySummary] = summaryGiveaway;

  const handleGoBack = () => {
    if (finishTwitter && confirmGiveawaySummary) {
      setConfirmGiveawaySummary(false);
    } else if (finishTwitter && !confirmGiveawaySummary) {
      setLike(false);
      setContent(false);
      setTwitterHandle(false);
      setRetweet(false);
      setInputRetweet("");
      setWalletPost("");
      setInputLike("");
      setInputContent("");
      setInputTwitterHandle("");
      setFinishTwitter(false);
    } else if (maticInfoSubmit || erc20InfoSubmit || erc721InfoSubmit) {
      setAddress("");
      setInputRetweet("");
      setInputLike("");
      setWalletPost("");
      setInputContent("");
      setInputTwitterHandle("");
      setRetweet(false);
      setLike(false);
      setContent(false);
      setTwitterHandle(false);
      setMaticInfoSubmit(false);
      setErc20InfoSubmit(false);
      setErc721InfoSubmit(false);
    } else if (assetChosen === "MATIC") {
      setChosenDistributionMethod(null);
      setMaticSubmitted(false);
    } else if (assetChosen === "ERC20") {
      setChosenDistributionMethod(null);
      setErc20Token(null);
      setErc20Submitted(false);
    } else if (assetChosen === "ERC721") {
      setErc721Submitted(false);
      setNftsArray([]);
    }
  };

  return (
    <div className="flex items-center gap-[1em] sm500:gap-[2em] md:gap-[3em] lg:gap-[4em] mb-[1em] sm:mb-[2em] lg:mb-[3em]">
      <div
        className="flex items-center gap-[.5em] cursor-pointer"
        onClick={() => handleGoBack()}
      >
        <TiArrowBack className="text-[18px] sm:text-[20px] md:text-[22px] lg:text-[24px]" />
        <p className="font-semibold text-[10px] md:text-[12px] lg:text-[14px]">
          Go back
        </p>
      </div>
      {!finishTwitter && (
        <div className="flex items-center gap-[2em]">
          <div className="flex items-center gap-[.4em] lg:gap-[.6em]">
            <BsCircleFill className="text-[#0DB145] text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px]" />
            <div className="flex gap-[.4em] items-center">
              <img
                src={
                  assetChosen === "MATIC"
                    ? maticLogo
                    : assetChosen === "ERC721"
                    ? ethLogoTwo
                    : erc20Token === "Ethereum"
                    ? ethLogo
                    : erc20Token === "USDT"
                    ? usdtLogo
                    : usdcLogo
                }
                alt={"Crypto Logo"}
                className="w-[14px] h-[14px] md:w-[16px] md:h-[16px] lg:w-[18px] lg:h-[18px]"
              />
              <p
                className={`${
                  assetChosen === "ERC20" || assetChosen === "ERC721"
                    ? "text-[#627eea]"
                    : "text-[#8247E5]"
                }  font-semibold text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px]`}
              >
                {assetChosen === "MATIC"
                  ? "Matic token"
                  : assetChosen === "ERC721"
                  ? "ERC721 token"
                  : erc20Token === "Ethereum"
                  ? "ETH"
                  : erc20Token}
              </p>
            </div>
          </div>
          {(assetChosen === "MATIC" || assetChosen === "ERC20") && (
            <div className="flex items-center gap-[.4em] text-center">
              <BsCircleFill className="text-[#0DB145] text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px]" />
              <p
                className={`${
                  assetChosen === "ERC20" || assetChosen === "ERC721"
                    ? "text-[#627eea]"
                    : "text-[#8247E5]"
                } font-semibold text-[10px] md:text-[14px] lg:text-[16px]`}
              >
                {chosenDistributionMethod === "exact"
                  ? "Exact number of tokens"
                  : "Percentage token split"}
              </p>
            </div>
          )}
          {assetChosen === "ERC721" && (
            <div className="flex items-center gap-[.6em]">
              <BsCircleFill className="text-[#0DB145] text-[12px] md:text-[14px] lg:text-[16px]" />
              <p className="text-[#627eea] font-semibold text-[12px] md:text-[14px] lg:text-[16px]">
                {nftsArray.length + " NFTs chosen"}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TopContainer;
