import React from "react";
import Confetti from "react-confetti";
import { useEffect, useState } from "react";

function FinishScreen() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Confetti width={windowSize.width * 0.99} height={windowSize.height} />
    </div>
  );
}

export default FinishScreen;
