import React, { useState, useEffect, useContext } from "react";
import TopContainer from "../parts/TopContainer";
import InputAddress from "../parts/InputAddress";
import NumOfWinners from "../parts/NumOfWinners";
import TotalReward from "../parts/TotalReward";
import NumOfTokens from "../parts/NumOfTokens";
import GiveawayDuration from "../parts/GiveawayDuration";
import SubmitButton from "../parts/SubmitButton";
import { LotteryContext } from "./LotteryBox";

function TokenSpecifics({ type }) {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 500);
  const { distributionMethod, totalPercReward } = useContext(LotteryContext);

  const [chosenDistributionMethod, setChosenDistributionMethod] =
    distributionMethod;

  const updateMedia = () => {
    setDesktop(window.innerWidth > 500);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="flex flex-col font-inter p-[20px] md:p-[25px] lg:p-[30px]">
      <TopContainer />
      <div className="mt-[2em] mb-[1em]">
        <InputAddress />
      </div>
      <div className="flex w-full flex-wrap items-end justify-between mb-[3em] gap-[.5em] sm400:gap-[.65em]">
        {chosenDistributionMethod === "exact" && <NumOfWinners />}
        {chosenDistributionMethod === "percentage" && <TotalReward />}
        <NumOfTokens />
        <GiveawayDuration />
        {!isDesktop && (
          <div className="flex justify-center">
            <SubmitButton type={type} />
          </div>
        )}
      </div>

      {isDesktop && (
        <div className="flex justify-center mt-[1em] sm:mt-[3em] lg:mt-[4em] md:mt-[1em] mb-[4em]">
          <SubmitButton type={type} />
        </div>
      )}
    </div>
  );
}

export default TokenSpecifics;
