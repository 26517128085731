import React, { useContext } from "react";
import { LotteryContext } from "../main/LotteryBox";

import { toast } from "react-toastify";
import { useMetaMask } from "metamask-react";
import axios from "axios";

function SubmitButton({ type }) {
  const {
    duration,
    distributionMethod,
    submitInfo,
    numTokens,
    wallet,
    winners,
    totalPercReward,
    erc20,
    assetChoice,
  } = useContext(LotteryContext);
  const [durationInHours, setDurationInHours] = duration;
  const [chosenDistributionMethod, setChosenDistributionMethod] =
    distributionMethod;
  const [setMaticInfoSubmit, setErc20InfoSubmit, setErc721InfoSubmit] =
    submitInfo;
  const [numOfTokens, setNumOfTokens] = numTokens;
  const [address, setAddress] = wallet;
  const [numOfWinners, setNumOfWinners] = winners;
  const [totalPercentageReward, setTotalPercentageReward] = totalPercReward;
  const [erc20Token, setErc20Token] = erc20;
  const [assetChosen, setAssetChosen] = assetChoice;

  const { chainId } = useMetaMask();

  let checkConditionAndThrowError = (condition, error) => {
    if (condition) {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const fetchTokenPrice = async () => {
    const symbol = assetChosen === "MATIC" ? "MATIC" : "ETH";

    const data = await axios.post(
      "https://baseapi.vaiot.ai/api/v1/asset/getAssetPrice",
      {
        symbol: symbol,
      }
    );

    if (data !== undefined) {
      const price =
        assetChosen === "MATIC"
          ? data.data.price.MATIC.quote.USD.price
          : assetChosen === "ERC20"
          ? data.data.price.ETH.quote.USD.price
          : 0;
      return price;
    }
  };

  const handleSubmit = async () => {
    let dotsArray = [];
    let incorrectDots = false;
    let incorrectZeros = false;
    let incorrectLength = false;
    let startsWithDot = false;
    let zeroThenDot = false;
    let sum = 0;
    let totalReward = 0;
    let maticPrice = 0;
    let ethPrice = 0;

    if (chosenDistributionMethod === "exact") {
      totalReward = numOfTokens
        .split(",")
        .reduce((partialSum, a) => partialSum + parseFloat(a), 0);
    }

    let arraySplit = numOfTokens.split(",");
    arraySplit.forEach((el) => {
      dotsArray.push(el.split(".").length - 1);
    });

    for (let i = 0; i < dotsArray.length; i++) {
      if (dotsArray[i] > 1) {
        incorrectDots = true;
      }
    }

    for (let i = 0; i < arraySplit.length; i++) {
      if (arraySplit[i][0] === "0" && arraySplit[i][1] === "0") {
        incorrectZeros = true;
      }
      if (arraySplit[i].length === 0) {
        incorrectLength = true;
      }
      if (arraySplit[i].length === 1 && arraySplit[i][0] === ".") {
        startsWithDot = true;
      }
      if (arraySplit[i][0] === "0" && arraySplit[i][1] !== ".") {
        zeroThenDot = true;
      }
      sum = sum + parseInt(arraySplit[i]);
    }

    const assetPrice = await fetchTokenPrice();

    if (
      (process.env.REACT_APP_DEV_ENV === "production" &&
        type === "matic" &&
        chainId === "0x89") ||
      (process.env.REACT_APP_DEV_ENV === "production" &&
        type === "erc20" &&
        chainId === "0x1") ||
      (process.env.REACT_APP_DEV_ENV === "production" &&
        type === "erc721" &&
        chainId === "0x1") ||
      (process.env.REACT_APP_DEV_ENV === "development" &&
        type === "matic" &&
        chainId === "0x13881") ||
      (process.env.REACT_APP_DEV_ENV === "development" &&
        type === "erc20" &&
        chainId === "0x5") ||
      (process.env.REACT_APP_DEV_ENV === "development" &&
        type === "erc721" &&
        chainId === "0x5")
    ) {
      if (address === "") {
        checkConditionAndThrowError(address === "", "Please input your wallet");
      } else if (numOfTokens.length < 1 && type !== "erc721") {
        checkConditionAndThrowError(
          numOfTokens.length < 1,
          "Please input the number of tokens!"
        );
      } else if (
        chosenDistributionMethod === "exact" &&
        numOfTokens.split(",").length !== numOfWinners &&
        type !== "erc721"
      ) {
        checkConditionAndThrowError(
          numOfTokens.split(",").length !== numOfWinners,
          "Number of tokens has to match the number of winners!"
        );
      } else if (
        incorrectDots &&
        chosenDistributionMethod === "exact" &&
        type !== "erc721"
      ) {
        checkConditionAndThrowError(
          incorrectDots,
          "Incorrect token input, there are too many dots!"
        );
      } else if (incorrectZeros && type !== "erc721") {
        checkConditionAndThrowError(
          incorrectZeros,
          "Token input can't start with more than one zero!"
        );
      } else if (zeroThenDot && type !== "erc721") {
        checkConditionAndThrowError(zeroThenDot, "Incorrect token input!");
      } else if (incorrectLength && type !== "erc721") {
        checkConditionAndThrowError(
          incorrectLength,
          "Token input can't be empty!"
        );
      } else if (address.length !== 42) {
        checkConditionAndThrowError(
          address.length !== 42,
          "Address length is incorrect!"
        );
      } else if (address.slice(0, 2) !== "0x") {
        checkConditionAndThrowError(
          address.slice(0, 2) !== "0x",
          "Please provide a valid Ethereum wallet!"
        );
      } else if (
        startsWithDot &&
        chosenDistributionMethod === "exact" &&
        type !== "erc721"
      ) {
        checkConditionAndThrowError(
          startsWithDot,
          "Input cannot start with a dot!"
        );
      } else if (
        numOfWinners === "0" &&
        type !== "erc721" &&
        chosenDistributionMethod === "exact"
      ) {
        checkConditionAndThrowError(
          numOfWinners === "0",
          "Number of winners has to be greater than zero!"
        );
      } else if (durationInHours < 1) {
        checkConditionAndThrowError(
          durationInHours < 1,
          "Giveaway duration must be minimum 1 hour!"
        );
      } else if (
        chosenDistributionMethod === "percentage" &&
        sum !== 100 &&
        type !== "erc721"
      ) {
        checkConditionAndThrowError(
          sum !== 100,
          "Token percentages must sum to 100!"
        );
      } else if (
        chosenDistributionMethod === "percentage" &&
        type !== "erc721" &&
        totalPercentageReward === "0"
      ) {
        checkConditionAndThrowError(
          totalPercentageReward === "0",
          "Total reward must be greater than zero!"
        );
      } else if (
        chosenDistributionMethod === "exact" &&
        type === "erc20" &&
        erc20Token !== "Ethereum" &&
        totalReward > 10000
        ////////////////////////////////////////////////////////////////////////////////////////////////
      ) {
        checkConditionAndThrowError(
          totalReward > 10000,
          "Maximum reward can't be greater than 10 000 USD!"
        );
      } else if (
        chosenDistributionMethod === "percentage" &&
        type === "erc20" &&
        erc20Token !== "Ethereum" &&
        totalPercentageReward > 10000
      ) {
        checkConditionAndThrowError(
          totalPercentageReward > 10000,
          "Maximum reward can't be greater than 10 000 USD!"
        );
      } else if (
        chosenDistributionMethod === "exact" &&
        type !== "erc721" &&
        erc20Token !== "USDT" &&
        erc20Token !== "USDC" &&
        totalReward * parseFloat(assetPrice) > 10000
      ) {
        checkConditionAndThrowError(
          totalReward * parseFloat(assetPrice) > 10000,
          "Maximum reward can't be greater than 10 000 USD!"
        );
      } else if (
        chosenDistributionMethod === "percentage" &&
        type !== "erc721" &&
        erc20Token !== "USDT" &&
        erc20Token !== "USDC" &&
        totalPercentageReward * parseFloat(assetPrice) > 10000
      ) {
        checkConditionAndThrowError(
          totalPercentageReward * parseFloat(assetPrice) > 10000,
          "Maximum reward can't be greater than 10 000 USD!"
        );
      } else if (
        chosenDistributionMethod === "percentage" &&
        type !== "erc721" &&
        totalPercentageReward === ""
      ) {
        checkConditionAndThrowError(
          totalPercentageReward === "",
          "Total reward must not be empty!"
        );
      } else {
        type === "matic"
          ? setMaticInfoSubmit(true)
          : type === "erc20"
          ? setErc20InfoSubmit(true)
          : setErc721InfoSubmit(true);
      }
    } else {
      toast.error(
        `Please switch your chain to ${
          type === "matic" ? "Polygon" : "Ethereum"
        }!`,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  return (
    <div>
      <button
        onClick={() => handleSubmit()}
        className="bg-[#5A5574] hover:text-[#d8d5d5] px-[28px] rounded-xl py-[12px] font-semibold text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px]"
      >
        SUBMIT
      </button>
    </div>
  );
}

export default SubmitButton;
