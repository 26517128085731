import React, { useState, useEffect, useContext } from "react";
import { LotteryContext } from "./LotteryBox";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { useMetaMask } from "metamask-react";
import useFetchNfts from "../../../hooks/useFetchNfts";
import MoonLoader from "react-spinners/MoonLoader";
import "../../../App.css";
import NFTCard from "./NFTCard";
import { toast } from "react-toastify";

function ERC721Chosen({}) {
  const { nfts, submit } = useContext(LotteryContext);

  const [setErc20Submitted, setMaticSubmitted, setErc721Submitted] = submit;
  const [nftsArray, setNftsArray] = nfts;
  const [readyToRender, setReadyToRender] = useState(false);
  const [chosenNfts, setChosenNfts] = useState([]);
  const { account, chainId } = useMetaMask();
  const { allNfts, promiseInProgress } = useFetchNfts(account);

  const handleErc721Choice = () => {
    if (
      (chainId === "0x1" && process.env.REACT_APP_DEV_ENV === "production") ||
      (chainId === "0x5" && process.env.REACT_APP_DEV_ENV === "development")
    ) {
      if (chosenNfts.length >= 1) {
        setMaticSubmitted(false);
        setErc20Submitted(false);
        setErc721Submitted(true);
      } else {
        toast.error("Please choose at least one NFT!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(
        `Please switch your chain to ${
          process.env.REACT_APP_DEV_ENV === "production"
            ? "Ethereum"
            : "Goerli Testnet"
        }!`,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  const updatePickedNfts = (nft) => {
    if (chosenNfts.includes(nft)) {
      const newNftsArray = chosenNfts.filter((i) => i !== nft);
      setNftsArray(newNftsArray);
      setChosenNfts(newNftsArray);
    } else {
      if (chosenNfts.length !== 5) {
        const newNftsArray = [...chosenNfts, nft];
        setChosenNfts(newNftsArray);
        setNftsArray(newNftsArray);
      }
    }
  };

  const nftsDiv =
    allNfts.length === 0 ? (
      <div></div>
    ) : (
      allNfts.map((nft, index) => (
        <div
          className="h-[80px] w-[80px] lg:w-[160px] lg:h-[160px]"
          key={index}
        >
          <NFTCard
            image={nft[1]}
            nft={nft}
            updatePickedNfts={updatePickedNfts}
            chosenNfts={chosenNfts}
          />
        </div>
      ))
    );

  useEffect(() => {
    if (!promiseInProgress) {
      setTimeout(() => {
        setReadyToRender(true);
      }, 10000);
    }
  }, [promiseInProgress]);

  return (
    <div>
      <div>
        <h1 className="text-[14px] sm:text-[20px] md:text-[22px] font-semibold mx-[10px] sm400:w-[350px] sm:w-[500px] lg:w-[550px] text-center mt-[1em] mb-[1em]">
          Select ERC721 tokens in the order you wish to give them away (up to 5)
        </h1>
      </div>
      {!promiseInProgress && (
        <div className="hidden">
          <ScrollMenu>
            <div className="flex gap-[10px] w-[200px] h-[150px]">{nftsDiv}</div>
          </ScrollMenu>
        </div>
      )}
      {readyToRender && (
        <ScrollMenu>
          <div className="flex gap-[10px] h-[80px] w-[80px] lg:w-[160px] lg:h-[160px] mb-[1em]">
            {nftsDiv}
          </div>
        </ScrollMenu>
      )}
      {!readyToRender && (
        <div className="w-full flex items-center justify-center my-[3em]">
          <MoonLoader size={32} color={"#ffff"} />
        </div>
      )}

      <div className="w-full flex items-center justify-center mt-[1em] sm500:mt-[2em] lg:mt-[4em]">
        <button
          className="bg-[#5A5574] hover:text-[#d8d5d5] px-[20px] py-[6px] sm:px-[30px] rounded-xl sm:py-[8px] lg:py-[12px] lg:px-[35px] lg:text-[18px] font-semibold text-[10px] sm:text-[14px]"
          onClick={() => handleErc721Choice()}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
}

export default ERC721Chosen;
