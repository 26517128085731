import React, { useState, useEffect, useRef } from "react";
import BegginingScreen from "../parts/BegginingScreen";
import RightButtons from "../parts/RightButtons";
import DzinChat from "../parts/DzinChat";
import DzinButtons from "../parts/DzinButtons";
import ThinkingComponent from "../parts/ThinkingComponent";
import NoSA from "../parts/paths/NoSA";
import YesSA from "../parts/paths/YesSA";

export const FormContext = React.createContext();

function ContractForm() {
  const [creatorStarted, setCreatorStarted] = useState(false);
  const [serviceChoice, setServiceChoice] = useState("");
  const [thinking, setThinking] = useState(false);
  const [thinkingStep, setThinkingStep] = useState(1);
  const [serviceAgreement, setServiceAgreement] = useState("");
  const [informEmail, setInformEmail] = useState("");
  const [email, setEmail] = useState("");
  const [currentStep, setCurrentStep] = useState("Choose Service");
  const [buyerSupplier, setBuyerSupplier] = useState("");
  const [orgPrivate, setOrgPrivate] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [cityBase, setCityBase] = useState("");
  const [countryBase, setCountryBase] = useState("");
  const [oneOfSigners, setOneOfSigners] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [roleInOrg, setRoleInOrg] = useState("");
  const [buyerPrivate, setBuyerPrivate] = useState("");
  const [fullNameBuyer, setFullNameBuyer] = useState("");
  const [buyerStreetName, setBuyerStreetName] = useState("");
  const [buyerStreetNumber, setBuyerStreetNumber] = useState("");
  const [buyerPostalCode, setBuyerPostalCode] = useState("");
  const [buyerCity, setBuyerCity] = useState("");
  const [buyerCountry, setBuyerCountry] = useState("");
  const [oneOfSignersTwo, setOneOfSignersTwo] = useState("");

  const contextObj = {
    creatorStart: [creatorStarted, setCreatorStarted],
    chosenService: [serviceChoice, setServiceChoice],
    isThinking: [thinking, setThinking],
    stepThinking: [thinkingStep, setThinkingStep],
    agreementService: [serviceAgreement, setServiceAgreement],
    isInformedByEmail: [informEmail, setInformEmail],
    emailAddress: [email, setEmail],
    currStep: [currentStep, setCurrentStep],
    buySupply: [buyerSupplier, setBuyerSupplier],
    organizationPriv: [orgPrivate, setOrgPrivate],
    suppName: [supplierName, setSupplierName],
    street: [streetName, setStreetName],
    streetNum: [streetNumber, setStreetNumber],
    postal: [postalCode, setPostalCode],
    cityBased: [cityBase, setCityBase],
    countryBased: [countryBase, setCountryBase],
    oneOfSign: [oneOfSigners, setOneOfSigners],
    firstN: [firstName, setFirstName],
    lastN: [lastName, setLastName],
    role: [roleInOrg, setRoleInOrg],
    buyerPriv: [buyerPrivate, setBuyerPrivate],
    buyerName: [fullNameBuyer, setFullNameBuyer],
    buyerStreet: [buyerStreetName, setBuyerStreetName],
    buyerStreetNum: [buyerStreetNumber, setBuyerStreetNumber],
    buyerPostal: [buyerPostalCode, setBuyerPostalCode],
    buyerCityBased: [buyerCity, setBuyerCity],
    buyerCountryBased: [buyerCountry, setBuyerCountry],
    oneOfSignersSecond: [oneOfSignersTwo, setOneOfSignersTwo],
  };

  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current.scrollHeight) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [
    serviceChoice,
    serviceAgreement,
    informEmail,
    thinkingStep,
    email,
    buyerSupplier,
    orgPrivate,
    supplierName,
    streetName,
    streetNumber,
    postalCode,
    cityBase,
    countryBase,
    oneOfSigners,
    firstName,
    lastName,
    roleInOrg,
    buyerPrivate,
    fullNameBuyer,
    buyerStreetName,
    buyerStreetNumber,
    buyerPostalCode,
    buyerCity,
    buyerCountry,
  ]);

  useEffect(() => {
    if (thinkingStep === 2) {
      setCurrentStep("Service Agreement");
    } else if (thinkingStep === 3 && serviceAgreement === "Yes") {
      setCurrentStep("Supplier Buyer Choice");
    } else if (thinkingStep === 3 && serviceAgreement === "No") {
      setCurrentStep("Inform Email");
    } else if (
      thinkingStep === 4 &&
      serviceAgreement === "Yes" &&
      buyerSupplier !== ""
    ) {
      setCurrentStep("OrganizationOrPrivate");
    } else if (
      thinkingStep === 4 &&
      serviceAgreement === "No" &&
      informEmail === "No"
    ) {
      setCurrentStep("Finished no email");
    } else if (
      thinkingStep === 4 &&
      serviceAgreement === "No" &&
      informEmail === "Yes"
    ) {
      setCurrentStep("Inputting email");
    } else if (
      thinkingStep === 5 &&
      serviceAgreement === "No" &&
      informEmail === "Yes" &&
      email !== ""
    ) {
      setCurrentStep("Finished with email");
    }
  }, [thinkingStep]);

  return (
    <div className="flex flex-col justify-center items-center w-full h-full ">
      <div className="flex justify-between w-full mb-[10px] max-h-[380px] min-h-[500px] min-w-[300px] mx-[20px] sm:max-h-[430px] md:min-h-[600px] lg:min-h-[620px] max-w-[900px] rounded-lg overflow-hidden bg-gradient-to-b from-[#332F47] via-[#322e52] to-[#332F47]">
        <div className="w-full">
          <FormContext.Provider value={contextObj}>
            <div
              className="h-full w-full flex flex-col gap-[20px] overflow-y-auto p-[10px]"
              ref={divRef}
            >
              {!creatorStarted && (
                <BegginingScreen setCreatorStarted={setCreatorStarted} />
              )}
              {creatorStarted && (
                <DzinChat avatar={true} content={"How may I help you?"} />
              )}
              {creatorStarted && (
                <DzinButtons
                  content={[
                    "Create a new contract",
                    "Continue on a contract",
                    "End a contract",
                    "Edit Terms",
                  ]}
                  setFunc={setServiceChoice}
                  funcValue={serviceChoice}
                  setThinking={setThinking}
                  setThinkingStep={setThinkingStep}
                />
              )}
              {creatorStarted &&
                serviceChoice === "Create a new contract" &&
                thinkingStep >= 2 && (
                  <div>
                    <DzinChat
                      avatar={true}
                      content={"Great, let me help you create a new contract."}
                    />
                    <DzinChat
                      avatar={false}
                      content={
                        "Please note that I can only help you with agreements for the acquisition of services. In the near future, I will also be able to help you with different types of contracts like purchasing agreements for goods, employment contracts, NDA’s or escrow agreements."
                      }
                    />
                    <DzinChat
                      avatar={false}
                      content={
                        "Do you want to continue to create a Service Agreement?"
                      }
                      lastOne={true}
                    />
                    <DzinButtons
                      content={["Yes", "No"]}
                      setFunc={setServiceAgreement}
                      funcValue={serviceAgreement}
                      setThinking={setThinking}
                      setThinkingStep={setThinkingStep}
                    />
                  </div>
                )}
              {serviceAgreement === "Yes" && <YesSA />}
              {serviceAgreement === "No" && <NoSA />}

              {thinking && <ThinkingComponent />}
            </div>
          </FormContext.Provider>
        </div>
      </div>
      <FormContext.Provider value={contextObj}>
        {creatorStarted && <RightButtons />}
      </FormContext.Provider>
    </div>
  );
}

export default ContractForm;
