import React, { useContext } from "react";
import TopContainer from "../parts/TopContainer";
import { LotteryContext } from "./LotteryBox";
import usdtLogo from "../../../images/usdtLogo.svg";
import ethLogo from "../../../images/ethLogoTwo.svg";
import ethLogoTwo from "../../../images/ethereum.png";
import usdcLogo from "../../../images/usdcLogo.svg";
import maticLogo from "../../../images/polygonLogo.svg";
import ScrollNfts from "../parts/ScrollNfts";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

function GiveawaySummary({
  setRetweet,
  setLike,
  setContent,
  setTwitterHandle,
  setConfirmGiveawaySummary,
}) {
  const {
    duration,
    distributionMethod,
    numTokens,
    wallet,
    assetChoice,
    erc20,
    nfts,
    totalPercReward,
  } = useContext(LotteryContext);
  const [durationInHours, setDurationInHours] = duration;
  const [chosenDistributionMethod, setChosenDistributionMethod] =
    distributionMethod;
  const [numOfTokens, setNumOfTokens] = numTokens;
  const [address, setAddress] = wallet;
  const [assetChosen, setAssetChosen] = assetChoice;
  const [erc20Token, setErc20Token] = erc20;
  const [nftsArray, setNftsArray] = nfts;
  const [totalPercentageReward, setTotalPercentageReward] = totalPercReward;
  return (
    <div className="flex flex-col font-inter p-[30px] h-full">
      <TopContainer
        setRetweet={setRetweet}
        setLike={setLike}
        setContent={setContent}
        setTwitterHandle={setTwitterHandle}
      />

      <h2 className="font-bold text-[14px] sm500:text-[16px] md:text-[18px] text-center mb-[1.5em] md:mb-[2em] lg:mb-[3em]">
        Giveaway Contract summary
      </h2>
      <div className="flex flex-col gap-[1em] lg:gap-[2em]">
        <div className="flex gap-[1em] md:gap-[2em] items-center">
          <p className="text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
            Your wallet address
          </p>
          <div className="bg-[#393354] text-center flex-1 rounded-2xl text-[12px] sm500:text-[14px] text-[#A49D9D] sm500:h-[22px] max-w-[500px] md:text-[16px] md:h-[26px] lg:h-[30px] lg:text-[18px]">
            {address.slice(0, 5) + "..." + address.slice(38, 42)}
          </div>
        </div>
        <div className="flex gap-[1em] md:gap-[2em] items-center">
          <p className="text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
            Giveaway Duration
          </p>
          <div className="bg-[#393354] text-center flex-1 rounded-2xl text-[12px] text-[#A49D9D] sm500:h-[22px] sm500:text-[14px] max-w-[500px] md:text-[16px] md:h-[26px] lg:h-[30px] lg:text-[18px]">
            {durationInHours === 1
              ? durationInHours + " hour"
              : durationInHours + " hours"}
          </div>
        </div>
        {assetChosen === "ERC721" && (
          <div className="flex gap-[1em] md:gap-[2em] items-center">
            <p className="text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
              Giveaway Contract cost
            </p>
            <div className="flex gap-[3px] sm500:gap-[5px] md:gap-[7px] lg:gap-[9px]">
              <p className="text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px] text-[#A49D9D]">
                0.01 USD
              </p>
            </div>
          </div>
        )}
        {assetChosen !== "ERC721" && (
          <div className="flex gap-[1em] md:gap-[2em] items-center">
            <p className="text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
              Selected asset
            </p>
            <div className="flex gap-[5px] items-center">
              <img
                className="w-[18px] h-[18px] sm500:w-[20px] sm500:h-[20px] md:w-[22px] md:h-[22px] lg:w-[24px] lg:h-[24px]"
                src={
                  erc20Token === "USDC"
                    ? usdcLogo
                    : erc20Token === "USDT"
                    ? usdtLogo
                    : erc20Token === "Ethereum"
                    ? ethLogo
                    : assetChosen === "MATIC"
                    ? maticLogo
                    : ethLogoTwo
                }
              />
              <p
                className={`text-[12px] sm500:text-[14px] ${
                  assetChosen === "ERC20" || assetChosen === "ERC721"
                    ? "text-[#627eea]"
                    : "text-[#8247E5]"
                } md:text-[16px] lg:text-[18px]`}
              >
                {erc20Token === "USDC"
                  ? "USDC"
                  : erc20Token === "USDT"
                  ? "USDT"
                  : erc20Token === "Ethereum"
                  ? "Ethereum"
                  : assetChosen === "MATIC"
                  ? "MATIC"
                  : nftsArray.length + " NFTs"}
              </p>
            </div>
          </div>
        )}
        <div>
          {assetChosen === "ERC721" && (
            <ScrollMenu>
              <ScrollNfts />
            </ScrollMenu>
          )}
          {assetChosen !== "ERC721" && (
            <div className="flex flex-col gap-[1em]  lg:gap-[2em]">
              <div className="flex gap-[1em] md:gap-[2em] items-center">
                <p className="text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                  Distribution Method
                </p>
                <div className="bg-[#393354] text-center flex-1 rounded-2xl text-[12px] text-[#A49D9D] sm500:h-[22px] sm500:text-[14px] max-w-[500px] md:text-[16px] md:h-[26px] lg:h-[30px] lg:text-[18px]">
                  {chosenDistributionMethod === "exact"
                    ? "Exact number"
                    : "Percentage split"}
                </div>
              </div>
              <div className="flex gap-[1em] md:gap-[2em] items-center">
                <p className="text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                  Reward pool
                </p>
                <div className="flex gap-[3px] sm500:gap-[5px] md:gap-[7px] lg:gap-[9px]">
                  <img
                    className="w-[18px] h-[18px] sm500:w-[20px] sm500:h-[20px] md:w-[22px] md:h-[22px] lg:w-[24px] lg:h-[24px]"
                    src={
                      erc20Token === "USDC"
                        ? usdcLogo
                        : erc20Token === "USDT"
                        ? usdtLogo
                        : erc20Token === "Ethereum"
                        ? ethLogo
                        : assetChosen === "MATIC"
                        ? maticLogo
                        : ethLogoTwo
                    }
                  />
                  <p className="text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px] text-[#A49D9D]">
                    {chosenDistributionMethod === "exact"
                      ? numOfTokens
                          .split(",")
                          .reduce(
                            (partialSum, a) => partialSum + parseFloat(a),
                            0
                          )
                      : totalPercentageReward}
                  </p>
                </div>
              </div>
              <div className="flex gap-[1em] md:gap-[2em] items-center">
                <p className="text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px]">
                  Giveaway Contract cost
                </p>
                <div className="flex gap-[3px] sm500:gap-[5px] md:gap-[7px] lg:gap-[9px]">
                  <p className="text-[12px] sm500:text-[14px] md:text-[16px] lg:text-[18px] text-[#A49D9D]">
                    0.01 USD
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {assetChosen !== "ERC721" && (
        <div className="flex items-center w-full justify-center mt-[3em] sm500:mt-[2em] sm:mt-[3em] md:mt-[2em] lg:mt-[2em]">
          <button
            onClick={() => setConfirmGiveawaySummary(true)}
            className="bg-[#5A5574] hover:text-[#d8d5d5] px-[28px] rounded-xl py-[12px] font-semibold text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px]"
          >
            CONFIRM
          </button>
        </div>
      )}
      {assetChosen === "ERC721" && (
        <div className="flex items-center w-full justify-center mt-[1em] sm:mt-[2em] md:mt-[1em] lg:mt-[2em]">
          <button
            onClick={() => setConfirmGiveawaySummary(true)}
            className="bg-[#5A5574] hover:text-[#d8d5d5] px-[28px] rounded-xl py-[12px] font-semibold text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px]"
          >
            CONFIRM
          </button>
        </div>
      )}
    </div>
  );
}

export default GiveawaySummary;
