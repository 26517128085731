import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

function TwitterCheckbox({ setFunc, text }) {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 640);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 640);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="flex items-center">
      <Checkbox
        size={`${isDesktop ? "medium" : "small"}`}
        onClick={() => setFunc((prev) => !prev)}
      />
      <p className="text-[12px] sm:text-[14px] md:text-[16px]">{text}</p>
    </div>
  );
}

export default TwitterCheckbox;
