import React, { useRef } from "react";
import dzin from "../../../images/dzin.svg";
import { FiSend } from "react-icons/fi";
import { toast } from "react-toastify";

function DzinChat({
  content,
  avatar,
  lastOne,
  input,
  inputContent,
  setInputFunc,
  inputType,
  setThinking,
  setThinkingStep,
}) {
  const inputRef = useRef(null);

  const onFormSubmit = (event, type) => {
    event.preventDefault();
    let inputtedValue = inputRef.current.value;
    if (type === "email") {
      if (!inputtedValue.includes("@")) {
        toast.error("Please provide a valid email address!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      setThinking(true);
      setTimeout(() => {
        setThinking(false);
        setThinkingStep((prev) => prev + 1);
      }, 2000);
      setInputFunc(inputtedValue);
    }
  };

  return (
    <div
      className={`px-[15px] xxs370:px-[30px] sm:px-[40px] md:px-[60px] py-[5px] flex items-center justify-start gap-[20px]`}
    >
      {avatar && (
        <img
          src={dzin}
          className="h-[50px] w-[50px] xxs370:h-[55px] xxs370:w-[55px] sm:h-[62px] sm:w-[62px]"
        />
      )}
      {!input && (
        <div
          className={`${
            avatar ? "" : "ml-[70px] xxs370:ml-[75px] sm:ml-[82px]"
          } ${
            lastOne ? "mb-[20px] sm:mb-[30px]" : ""
          } text-[14px] xxs370:text-[16px] sm:text-[18px] bg-[#343149] text-[#B9B6B6] font-inter font-semibold px-[25px] py-[15px] rounded-2xl`}
        >
          {content}
        </div>
      )}
      {input && (
        <form
          className={`${
            avatar ? "" : "ml-[70px] xxs370:ml-[75px] sm:ml-[82px]"
          } ${
            lastOne ? "mb-[20px] sm:mb-[30px]" : ""
          } flex items-center overflow-hidden text-[12px] xxs370:text-[14px] sm:text-[16px] bg-[#343149] font-inter font-semibold px-[25px] py-[15px] rounded-2xl`}
          onSubmit={(event) => onFormSubmit(event, inputType)}
        >
          <input
            placeholder={inputContent}
            ref={inputRef}
            className="w-[130px] sm400:w-[230px] sm:w-[320px] bg-transparent outline-none placeholder:opacity-50 placeholder:text-[10px] xxs:placeholder:text-[12px] sm:placeholder:text-[14px]"
          ></input>
          <FiSend
            onClick={(e) => onFormSubmit(e, inputType)}
            className="text-[#B9B6B6] cursor-pointer"
          />
        </form>
      )}
    </div>
  );
}

export default DzinChat;
