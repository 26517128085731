import React from "react";
import dzin from "../../../images/dzin.svg";
import { ThreeDots } from "react-loader-spinner";

function ThinkingComponent() {
  return (
    <div className="px-[15px] xxs370:px-[30px] py-[10px] sm:px-[40px] sm:py-[20px] md:px-[60px] md:py-[30px] flex items-center justify-start gap-[20px]">
      <img
        src={dzin}
        className="h-[50px] w-[50px] xxs370:h-[55px] xxs370:w-[55px] sm:h-[62px] sm:w-[62px]"
      />
      <div className="px-[5px] md:hidden">
        <ThreeDots
          height="30"
          width="30"
          radius="9"
          color="#B9B6B6"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
      <div className="px-[5px] hidden md:block">
        <ThreeDots
          height="40"
          width="40"
          radius="9"
          color="#B9B6B6"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    </div>
  );
}

export default ThinkingComponent;
