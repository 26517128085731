import React, { useState, useEffect } from "react";
import { useMetaMask } from "metamask-react";
import ethLogo from "../../../images/ethereum.png";
import polygonLogo from "../../../images/polygonLogo.svg";
import { BiChevronDown, BiChevronUp, BiError } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import useComponentVisible from "../../../hooks/useComponentVisible";

function ConnectButton() {
  const { status, connect, account, chainId, switchChain, addChain } =
    useMetaMask();

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const connectMetamask = async () => {
    await connect();
  };

  const networkParams = {
    0x1: {
      chainId: `0x${Number(1).toString(16)}`,
      chainName: "Ethereum",
      rpcUrls: ["https://rpc.builder0x69.io"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      blockExplorerUrls: ["https://etherscan.io/"],
    },
    0x89: {
      chainId: `0x${Number(137).toString(16)}`,
      chainName: "Polygon",
      rpcUrls: ["https://polygon-rpc.com"],
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      blockExplorerUrls: ["https://polygonscan.com/"],
    },
    0x5: {
      chainId: `0x${Number(5).toString(16)}`,
      chainName: "Goerli",
      rpcUrls: ["https://endpoints.omniatech.io/v1/eth/goerli/public"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      blockExplorerUrls: ["https://goerli.etherscan.io/"],
    },
    0x13881: {
      chainId: `0x${Number(80001).toString(16)}`,
      chainName: "Mumbai",
      rpcUrls: ["https://endpoints.omniatech.io/v1/matic/mumbai/public"],
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
    },
  };

  const handleSwitchChain = async (name) => {
    try {
      await switchChain(`0x${Number(name.toString()).toString(16)}`);
    } catch (error) {
      if (error.code === 4902) {
        try {
          await addChain(networkParams[name]);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const [isDesktop, setDesktop] = useState(window.innerWidth > 500);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 500);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="flex justify-center items-center gap-[.75em] sm:gap-[1.5em]">
      <div
        ref={ref}
        onClick={() => setIsComponentVisible((prev) => !prev)}
        className="flex justify-center relative items-center gap-[.3em] cursor-pointer p-[4px] rounded-[8px]"
      >
        {process.env.REACT_APP_DEV_ENV === "production" &&
          chainId === "0x1" && (
            <img src={ethLogo} className="w-[20px] h-[20px] " />
          )}
        {process.env.REACT_APP_DEV_ENV === "development" &&
          chainId === "0x5" && (
            <img src={ethLogo} className="w-[20px] h-[20px] " />
          )}
        {process.env.REACT_APP_DEV_ENV === "production" &&
          chainId === "0x89" && (
            <img src={polygonLogo} className="w-[20px] h-[20px] " />
          )}
        {process.env.REACT_APP_DEV_ENV === "development" &&
          chainId === "0x13881" && (
            <img src={polygonLogo} className="w-[20px] h-[20px] " />
          )}
        {process.env.REACT_APP_DEV_ENV === "development" &&
          chainId !== "0x5" &&
          chainId !== "0x13881" && (
            <div className="flex items-center gap-[.2em]">
              <BiError className="w-[20px] h-[20px] text-[red]" />
              <p className="text-[16px] font-inter md:block hidden">
                Unsupported
              </p>
            </div>
          )}
        {process.env.REACT_APP_DEV_ENV === "production" &&
          chainId !== "0x1" &&
          chainId !== "0x89" && (
            <div className="flex items-center gap-[.2em]">
              <BiError className="w-[20px] h-[20px] text-[red]" />
              <p className="text-[16px] font-inter md:block hidden">
                Unsupported
              </p>
            </div>
          )}
        {!isComponentVisible && (
          <BiChevronDown className="text-[28px] hover:text-[#6DA8FF]" />
        )}
        {isComponentVisible && (
          <BiChevronUp className="text-[28px] hover:text-[#6DA8FF]" />
        )}
        {isComponentVisible && (
          <div className="bg-[#393a47] absolute top-[100%] w-[150px] sm:w-[200px] right-0 rounded-[10px] font-inter">
            <div>
              <div className="hover:bg-[#434554] rounded-[8px]">
                <div
                  className="mb-[10px] flex items-center justify-between p-[10px] pt-[15px]"
                  onClick={() =>
                    handleSwitchChain(
                      process.env.REACT_APP_DEV_ENV === "production" ? 0x1 : 0x5
                    )
                  }
                >
                  <div className="flex items-center">
                    <img
                      src={ethLogo}
                      className="w-[20px] h-[20px] mr-[10px]"
                    />
                    {process.env.REACT_APP_DEV_ENV === "production" && (
                      <p>Ethereum</p>
                    )}
                    {process.env.REACT_APP_DEV_ENV === "development" && (
                      <div>
                        <p className="hidden sm:block">Goerli Testnet</p>
                        <p className="block sm:hidden">Goerli</p>
                      </div>
                    )}
                  </div>
                  {process.env.REACT_APP_DEV_ENV === "development" &&
                    chainId === "0x5" && (
                      <AiOutlineCheck className="text-[#6DA8FF]" />
                    )}
                  {process.env.REACT_APP_DEV_ENV === "production" &&
                    chainId === "0x1" && (
                      <AiOutlineCheck className="text-[#6DA8FF]" />
                    )}
                </div>
              </div>
              <div className="hover:bg-[#434554] rounded-[8px]">
                <div
                  className="flex  items-center justify-between p-[10px] pb-[15px]"
                  onClick={() =>
                    handleSwitchChain(
                      process.env.REACT_APP_DEV_ENV === "production"
                        ? 0x89
                        : 0x13881
                    )
                  }
                >
                  <div className="flex items-center">
                    <img
                      src={polygonLogo}
                      className="w-[20px] h-[20px] mr-[10px]"
                    />
                    {process.env.REACT_APP_DEV_ENV === "production" && (
                      <p>Polygon</p>
                    )}
                    {process.env.REACT_APP_DEV_ENV === "development" && (
                      <div>
                        <p className="hidden sm:block">Mumbai Testnet</p>
                        <p className="block sm:hidden">Mumbai</p>
                      </div>
                    )}
                  </div>
                  {process.env.REACT_APP_DEV_ENV === "development" &&
                    chainId === "0x13881" && (
                      <AiOutlineCheck className="text-[#6DA8FF]" />
                    )}
                  {process.env.REACT_APP_DEV_ENV === "production" &&
                    chainId === "0x89" && (
                      <AiOutlineCheck className="text-[#6DA8FF]" />
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {status != "connected" && (
        <button
          className="bg-[rgb(21,61,111)] px-[16px] py-[8px] rounded-[12px] text-[#6DA8FF] text-[16px] font-inter font-semibold"
          onClick={() => connectMetamask()}
        >
          {isDesktop ? "Connect to a wallet" : "Connect"}
        </button>
      )}
      {status === "connected" && (
        <button className="bg-[rgb(21,61,111)] px-[12px] py-[6px] text-[12px] sm:px-[16px] sm:py-[8px] rounded-[12px] text-[#6DA8FF] sm:text-[14px] font-inter font-semibold">
          {account.slice(0, 5) + "..." + account.slice(38, 42)}
        </button>
      )}
    </div>
  );
}

export default ConnectButton;
