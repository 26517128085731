import React, { useContext, useState } from "react";
import { ethers } from "ethers";
import matic from "../../../abis/matic.json";
import usdc from "../../../abis/usdc.json";
import usdt from "../../../abis/usdt.json";
import { toast } from "react-toastify";
import { LotteryContext } from "../main/LotteryBox";
import axios from "axios";
import { useMetaMask } from "metamask-react";

function FinishButton({ setTransactionPending }) {
  const FEES_STABLECOIN = parseFloat(process.env.REACT_APP_FEES_STABLECOIN);
  const FEES_NOT_STABLECOIN = parseFloat(
    process.env.REACT_APP_FEES_NOT_STABLECOIN
  );
  const RECIPIENT_ADDRESS = process.env.REACT_APP_RECIPIENT_ADDRESS;

  const { chainId } = useMetaMask();

  const {
    assetChoice,
    distributionMethod,
    numTokens,
    wallet,
    totalPercReward,
    duration,
    winners,
    erc20,
    nfts,
    twitterInputs,
    finishTheLottery,
    authToken,
    authVerifier,
  } = useContext(LotteryContext);

  const [assetChosen, setAssetChosen] = assetChoice;
  const [chosenDistributionMethod, setChosenDistributionMethod] =
    distributionMethod;
  const [numOfTokens, setNumOfTokens] = numTokens;
  const [address, setAddress] = wallet;
  const [totalPercentageReward, setTotalPercentageReward] = totalPercReward;
  const [durationInHours, setDurationInHours] = duration;
  const [numOfWinners, setNumOfWinners] = winners;
  const [erc20Token, setErc20Token] = erc20;
  const [nftsArray, setNftsArray] = nfts;
  const [
    inputRetweet,
    inputLike,
    inputContent,
    inputTwitterHandle,
    walletPost,
  ] = twitterInputs;
  const [finishLottery, setFinishLottery] = finishTheLottery;
  const [oauth_Token, set_OAuthToken] = authToken;
  const [oauth_Verifier, set_OAuthVerifier] = authVerifier;

  let checkConditionAndThrowError = (condition, error) => {
    if (condition) {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const fetchNftAbis = async (fetchedPrice) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let txHashes = [];

    const nftAbis = await Promise.all(
      nftsArray.map(async (nft) => {
        let contractAddress = nft.contract.address;
        if (process.env.REACT_APP_DEV_ENV === "production") {
          const data = await axios.post(
            "https://baseapi.vaiot.ai/api/v1/abi/getContractAbi",
            {
              contractAddress: contractAddress,
            }
          );
          const fetchedAbi = data.data.abi.result;
          return fetchedAbi;
        } else {
          const data = await axios.post(
            "https://baseapi.vaiot.ai/api/v1/abi/getContractAbi",
            {
              contractAddress: contractAddress,
              testnet: true,
            }
          );
          const fetchedAbi = data.data.abi.result;
          return fetchedAbi;
        }
      })
    );

    let noError = true;

    const abisResult = nftAbis.map((abi) => {
      if (abi === "Contract source code not verified") {
        noError = false;
        checkConditionAndThrowError(
          abi === "Contract source code not verified",
          "NFT smart contract address is not verified!"
        );
        return "NOT VERIFIED";
      }
    });

    if (abisResult === "NOT VERIFIED") {
      return "NOT VERIFIED";
    }

    if (noError) {
      await Promise.all(
        nftsArray.map(async (nft, index) => {
          const tokenId = nft.tokenId;
          let contractAddress = nft.contract.address;
          const nonce = await provider.getTransactionCount(wallet[0]);
          const contract = new ethers.Contract(
            contractAddress,
            nftAbis[index],
            signer
          );
          const transferPromise = await contract.transferFrom(
            address,
            RECIPIENT_ADDRESS,
            parseInt(tokenId),
            { nonce: nonce, gasLimit: "250000" }
          );
          await transferPromise.wait();
          txHashes.push(transferPromise.hash);
          return transferPromise;
        })
      );

      await fillDatabase(fetchedPrice, txHashes);
      return true;
    }
  };

  const fetchTokenPrice = async () => {
    const symbol = assetChosen === "MATIC" ? "MATIC" : "ETH";

    const data = await axios.post(
      "https://baseapi.vaiot.ai/api/v1/asset/getAssetPrice",
      {
        symbol: symbol,
      }
    );

    if (data !== undefined) {
      const price =
        assetChosen === "MATIC"
          ? data.data.price.MATIC.quote.USD.price
          : assetChosen === "ERC20" || "ERC721"
          ? data.data.price.ETH.quote.USD.price
          : 0;
      return price;
    }
  };

  const fillDatabase = async (tokenPrice, tx_hash) => {
    let nftsFiltered = [];
    let sumOfFees = +(FEES_NOT_STABLECOIN / tokenPrice).toFixed(4);
    if (nftsArray !== undefined) {
      nftsArray.map((nft) =>
        nftsFiltered.push({
          name: nft.rawMetadata.name,
          token_id: parseInt(nft.tokenId),
          contract_address: nft.contract.address,
        })
      );
    }

    const data = {
      duration: parseInt(durationInHours),
      wallet: address,
      asset_choice: assetChosen,
      twitter: { wallet_post: walletPost },
    };

    if (assetChosen === "ERC721") {
      data.nfts_choice = nftsFiltered;
      data.tx_hashes = tx_hash;
    } else {
      data.tx_hashes = [tx_hash];
    }

    if (assetChosen === "ERC721") {
      data.num_of_winners = nftsArray.length;
    } else {
      if (chosenDistributionMethod === "percentage") {
        data.num_of_winners = numOfTokens.split(",").length;
      } else {
        data.num_of_winners = numOfWinners;
      }
    }

    if (assetChosen !== "ERC721") {
      data.distribution_method =
        chosenDistributionMethod === "exact" ? "SPLIT" : "PERCENTAGE";
    }

    if (erc20Token !== null && erc20Token !== "Ethereum") {
      data.fees_amount = (FEES_STABLECOIN * 10 ** 6).toString();
    } else {
      data.fees_amount = (parseFloat(sumOfFees) * 10 ** 18).toString();
    }

    if (numOfTokens !== "") {
      if (chosenDistributionMethod === "percentage") {
        data.distribution_options = numOfTokens
          .split(",")
          .map((item) => item.toString());
      } else if (chosenDistributionMethod === "exact") {
        if (erc20Token === "USDT" || erc20Token === "USDC") {
          data.distribution_options = numOfTokens
            .split(",")
            .map((item) => (parseFloat(item) * 10 ** 6).toString());
        } else {
          data.distribution_options = numOfTokens
            .split(",")
            .map((item) => (parseFloat(item) * 10 ** 18).toString());
        }
      }
    }

    if (erc20Token !== undefined && erc20Token !== "") {
      data.erc20_choice = erc20Token === "Ethereum" ? "ETH" : erc20Token;
    }

    if (numOfTokens !== "") {
      if (erc20Token === "USDT" || erc20Token === "USDC") {
        data.number_of_tokens =
          chosenDistributionMethod === "exact"
            ? (
                parseFloat(
                  numOfTokens
                    .split(",")
                    .reduce((partialSum, a) => partialSum + parseFloat(a), 0)
                ) *
                10 ** 6
              ).toString()
            : (parseFloat(totalPercentageReward) * 10 ** 6).toString();
      } else {
        data.number_of_tokens =
          chosenDistributionMethod === "exact"
            ? (
                parseFloat(
                  numOfTokens
                    .split(",")
                    .reduce((partialSum, a) => partialSum + parseFloat(a), 0)
                ) *
                10 ** 18
              ).toString()
            : (parseFloat(totalPercentageReward) * 10 ** 18).toString();
      }
    }
    if (inputLike !== "") {
      data.twitter.like = inputLike;
    }
    if (inputContent !== "") {
      data.twitter.content = inputContent;
    }
    if (inputRetweet !== "") {
      data.twitter.retweet = inputRetweet;
    }
    if (inputTwitterHandle !== "") {
      data.twitter.follow = inputTwitterHandle;
    }

    if (chosenDistributionMethod === "percentage") {
      const rewardsArray = [];
      let percentageArray = [];
      if (erc20Token === "USDT" || erc20Token === "USDC") {
        percentageArray = numOfTokens
          .split(",")
          .map((item) => parseFloat(item));
        percentageArray.map((item) =>
          rewardsArray.push((item * totalPercentageReward * 10 ** 4).toString())
        );
        data.final_rewards = rewardsArray;
      } else {
        percentageArray = numOfTokens
          .split(",")
          .map((item) => parseFloat(item));
        percentageArray.map((item) =>
          rewardsArray.push(
            (item * totalPercentageReward * 10 ** 16).toString()
          )
        );
        data.final_rewards = rewardsArray;
      }
    }
    await axios.post("https://baseapi.vaiot.ai/api/v1/lottery/create", data, {
      withCredentials: true,
    });
  };

  const transferTokens = async (tokenPrice) => {
    setTransactionPending(true);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const sumOfFees = +(FEES_NOT_STABLECOIN / tokenPrice).toFixed(4);
    if (provider !== undefined) {
      if (assetChosen === "MATIC") {
        const contract = new ethers.Contract(
          "0x0000000000000000000000000000000000001010",
          matic,
          signer
        );
        if (chosenDistributionMethod === "exact") {
          const sumOfRewards = numOfTokens
            .split(",")
            .reduce((partialSum, a) => partialSum + parseFloat(a), 0);
          const tx = await contract.transfer(
            RECIPIENT_ADDRESS,
            ethers.utils.parseEther((sumOfRewards + sumOfFees).toString()),
            {
              value: ethers.utils.parseEther(
                (sumOfRewards + sumOfFees).toString()
              ),
            }
          );

          return [true, tx];
        } else if (chosenDistributionMethod === "percentage") {
          const tx = await contract.transfer(
            RECIPIENT_ADDRESS,
            ethers.utils.parseEther(
              (parseFloat(totalPercentageReward) + sumOfFees).toString()
            ),
            {
              value: ethers.utils.parseEther(
                (parseFloat(totalPercentageReward) + sumOfFees).toString()
              ),
            }
          );
          return [true, tx];
        }
      } else if (assetChosen === "ERC20") {
        if (chosenDistributionMethod === "exact") {
          const sumOfRewards = numOfTokens
            .split(",")
            .reduce((partialSum, a) => partialSum + parseFloat(a), 0);
          if (erc20Token === "Ethereum") {
            const tx = await signer.sendTransaction({
              to: RECIPIENT_ADDRESS,
              value: ethers.utils.parseEther(
                (sumOfRewards + sumOfFees).toString()
              ),
            });
            return [true, tx];
          } else if (erc20Token === "USDT") {
            const contract = new ethers.Contract(
              "0xdAC17F958D2ee523a2206206994597C13D831ec7",
              usdt,
              signer
            );
            // 1 USDT = 1 * 10^6 !
            const tx = await contract.transfer(
              RECIPIENT_ADDRESS,
              (sumOfRewards + FEES_STABLECOIN) * 10 ** 6
            );
            return [true, tx];
          } else if (erc20Token === "USDC") {
            const contract = new ethers.Contract(
              "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
              usdc,
              signer
            );
            const tx = await contract.transfer(
              RECIPIENT_ADDRESS,
              (sumOfRewards + FEES_STABLECOIN) * 10 ** 6
            );
            return [true, tx];
          }
        } else if (chosenDistributionMethod === "percentage") {
          if (erc20Token === "Ethereum") {
            const tx = await signer.sendTransaction({
              to: RECIPIENT_ADDRESS,
              value: ethers.utils.parseEther(
                (parseFloat(totalPercentageReward) + sumOfFees).toString()
              ),
            });
            return [true, tx];
          } else if (erc20Token === "USDT") {
            const contract = new ethers.Contract(
              "0xdAC17F958D2ee523a2206206994597C13D831ec7",
              usdt,
              signer
            );
            // 1 USDT = 1 * 10^6 !
            const tx = await contract.transfer(
              RECIPIENT_ADDRESS,
              (parseFloat(totalPercentageReward) + FEES_STABLECOIN) * 10 ** 6
            );
            return [true, tx];
          } else if (erc20Token === "USDC") {
            const contract = new ethers.Contract(
              "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
              usdc,
              signer
            );
            const tx = await contract.transfer(
              RECIPIENT_ADDRESS,
              (parseFloat(totalPercentageReward) + FEES_STABLECOIN) * 10 ** 6
            );
            return [true, tx];
          }
        }
      } else if (assetChosen === "ERC721") {
        const nftResult = await fetchNftAbis(tokenPrice);
        return [false, nftResult];
      }
    }
  };

  const handleFinishLottery = async () => {
    const type =
      assetChosen === "ERC721"
        ? "erc721"
        : assetChosen === "MATIC"
        ? "matic"
        : "erc20";
    if (
      (process.env.REACT_APP_DEV_ENV === "production" &&
        type === "matic" &&
        chainId === "0x89") ||
      (process.env.REACT_APP_DEV_ENV === "production" &&
        type === "erc20" &&
        chainId === "0x1") ||
      (process.env.REACT_APP_DEV_ENV === "production" &&
        type === "erc721" &&
        chainId === "0x1") ||
      (process.env.REACT_APP_DEV_ENV === "development" &&
        type === "matic" &&
        chainId === "0x13881") ||
      (process.env.REACT_APP_DEV_ENV === "development" &&
        type === "erc20" &&
        chainId === "0x5") ||
      (process.env.REACT_APP_DEV_ENV === "development" &&
        type === "erc721" &&
        chainId === "0x5")
    ) {
      const fetchedPrice = await fetchTokenPrice();
      try {
        const transferReturn = await transferTokens(fetchedPrice);
        const success = transferReturn[0];
        const tx = transferReturn[1];
        if (tx === "NOT VERIFIED") {
          setTransactionPending(false);
          return false;
        }
        if (assetChosen !== "ERC721" && success) {
          await fillDatabase(fetchedPrice, tx.hash);
        }
        window.history.replaceState(null, "", window.location.pathname);
        setFinishLottery(true);
      } catch (error) {
        setTransactionPending(false);
      }
    } else {
      toast.error(
        `Please switch your chain to ${
          type === "matic" ? "Polygon" : "Ethereum"
        }!`,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  return (
    <div className="flex items-center w-full justify-center mt-[3em] sm:mt-[3em] md:mt-[1.5em] lg:mt-[3em]">
      <button
        onClick={() => handleFinishLottery()}
        className="bg-[#5A5574] hover:text-[#d8d5d5] px-[28px] rounded-xl py-[12px] font-semibold text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px]"
      >
        CONFIRM
      </button>
    </div>
  );
}

export default FinishButton;
