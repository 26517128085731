import React from "react";
import dzin from "../../../images/dzin.svg";

function BegginingScreen({ setCreatorStarted }) {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="flex gap-[20px] md:gap-[30px] lg:gap-[40px] justify-center items-center mb-[40px] lg:mb-[80px]">
        <img
          src={dzin}
          className="h-[90px] xxs:h-[120px] sm500:h-[140px] sm:h-[160px] md:h-[180px] lg:h-[200px]"
        />
        <div className="p-[20px] h-[110px] xxs:h-[130px] sm500:h-[150px] sm:h-[170px] md:h-[190px] sm500:w-[300px] sm:w-[350px] md:w-[400px] w-[240px] bg-[#343149] rounded-2xl text-[#B9B6B6] font-inter font-semibold text-[10px] xxs:text-[12px] sm500:text-[14px] sm:text-[16px] md:text-[18px]">
          Welcome to Intelligent Contracts Creator!
          <br />
          <br /> Together we’ll create a service delivery agreement in no time!
        </div>
      </div>
      <button
        onClick={() => setCreatorStarted(true)}
        className="cursor-pointer outline-none w-[120px] h-[40px] xxs:w-[140px] xxs:h-[50px] xxs:text-[13px] md:text-[15px] md:w-[160px] md:h-[60px] rounded-2xl bg-gradient-to-b from-[#5B58A6] to-[#332F47] text-[12px] font-semibold font-inter text-[#4F8DFC]"
      >
        GET STARTED
      </button>
    </div>
  );
}

export default BegginingScreen;
