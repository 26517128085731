import { useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import LotteryBox from "./components/Lottery/main/LotteryBox";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ContractForm from "./components/IntelligentContracts/main/ContractForm";

function App() {
  const [isLottery, setIsLottery] = useState(true);
  const [isContractCreator, setIsContractCreator] = useState(false);

  return (
    <div className="flex flex-col h-[100vh] w-screen">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Navbar
        isLottery={isLottery}
        setIsLottery={setIsLottery}
        isContractCreator={isContractCreator}
        setIsContractCreator={setIsContractCreator}
      />
      <div className="h-full">
        {isLottery && <LotteryBox />}
        {!isLottery && isContractCreator && <ContractForm />}
      </div>
    </div>
  );
}

export default App;
