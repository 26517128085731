import React from "react";
import {
  AiOutlineRetweet,
  AiFillHeart,
  AiOutlineTwitter,
} from "react-icons/ai";
import { BiWallet } from "react-icons/bi";
import { RiUserFollowFill } from "react-icons/ri";

function TwitterInput({ icon, setFunc, placeholder, inputTwitterHandle }) {
  return (
    <div className="w-full flex font-inter  mt-[.7em] md:mt-[1em] lg:mt-[2em] gap-[1em] ">
      {icon === "wallets" && (
        <BiWallet className="text-[#669AFF] lg:h-[40px] lg:w-[40px] md:w-[35px] md:h-[35px] sm:w-[30px] sm:h-[30px] w-[25px] h-[25px]" />
      )}
      {icon === "retweet" && (
        <AiOutlineRetweet className="text-[#669AFF] lg:h-[40px] lg:w-[40px] md:w-[35px] md:h-[35px] sm:w-[30px] sm:h-[30px] w-[25px] h-[25px]" />
      )}
      {icon === "like" && (
        <AiFillHeart className="text-[red] lg:h-[40px] lg:w-[40px] md:w-[35px] md:h-[35px] sm:w-[30px] sm:h-[30px] w-[25px] h-[25px]" />
      )}
      {icon === "content" && (
        <AiOutlineTwitter className="text-[#669AFF] lg:h-[40px] lg:w-[40px] md:w-[35px] md:h-[35px] sm:w-[30px] sm:h-[30px] w-[25px] h-[25px]" />
      )}
      {icon === "follow" && (
        <RiUserFollowFill className="text-[#669AFF] lg:h-[40px] lg:w-[40px] md:w-[35px] md:h-[35px] sm:w-[30px] sm:h-[30px] w-[25px] h-[25px]" />
      )}
      {icon !== "follow" && (
        <input
          onChange={(e) => setFunc(e.target.value)}
          className="bg-[#393354] pl-[10px] sm400:pl-[30px] outline-none flex-1 w-full rounded-2xl text-[#A49D9D] lg:text-[14px] md:text-[12px] text-[10px] placeholder:text-[#A49D9D] placeholder:font-semibold lg:placeholder:text-[14px] md:placeholder:text-[12px] placeholder:text-[10px]"
          placeholder={placeholder}
        />
      )}
      {icon === "follow" && (
        <input
          onChange={(e) => setFunc(e.target.value)}
          className="bg-[#393354] pl-[10px] sm400:pl-[30px] outline-none flex-1 w-full rounded-2xl text-[#A49D9D] lg:text-[14px] md:text-[12px] text-[10px] placeholder:text-[#A49D9D] placeholder:font-semibold lg:placeholder:text-[14px] md:placeholder:text-[12px] placeholder:text-[10px]"
          placeholder={placeholder}
          onFocus={() => setFunc("@")}
          value={inputTwitterHandle}
        />
      )}
    </div>
  );
}

export default TwitterInput;
