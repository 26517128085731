import React, { useState } from "react";
import SelectAssets from "./SelectAssets";
import StartingPage from "./StartingPage";
import TokenSpecifics from "./TokenSpecifics";
import ERC721Specifics from "./ERC721Specifics";
import TwitterConfiguration from "./TwitterConfiguration";
import GiveawaySummary from "./GiveawaySummary";
import TwitterSummary from "./TwitterSummary";
import Confetti from "./Confetti";
import FinishScreen from "./FinishScreen";

export const LotteryContext = React.createContext();

function LotteryBox() {
  // Twitter Account Variable
  const [oauth_Token, set_OAuthToken] = useState("");
  const [oauth_Verifier, set_OAuthVerifier] = useState("");

  // Lottery Start State Variable

  const [getStarted, setGetStarted] = useState(false);

  // Select Assets State Variables

  const [assetChosen, setAssetChosen] = useState();
  const [erc20Token, setErc20Token] = useState();
  const [chosenDistributionMethod, setChosenDistributionMethod] = useState();
  const [nftsArray, setNftsArray] = useState();
  const [maticSubmitted, setMaticSubmitted] = useState(false);
  const [erc20Submitted, setErc20Submitted] = useState(false);
  const [erc721Submitted, setErc721Submitted] = useState(false);

  // Specific Matic/ERC20/ERC721 Choice State Variables

  const [address, setAddress] = useState("");
  const [numOfWinners, setNumOfWinners] = useState(1);
  const [durationInHours, setDurationInHours] = useState(1);
  const [numOfTokens, setNumOfTokens] = useState("");
  const [totalPercentageReward, setTotalPercentageReward] = useState("");
  const [maticInfoSubmit, setMaticInfoSubmit] = useState(false);
  const [erc20InfoSubmit, setErc20InfoSubmit] = useState(false);
  const [erc721InfoSubmit, setErc721InfoSubmit] = useState(false);

  // Twitter Configuration State Variables - True/False

  const [retweet, setRetweet] = useState(false);
  const [like, setLike] = useState(false);
  const [content, setContent] = useState(false);
  const [twitterHandle, setTwitterHandle] = useState(false);

  // Twitter Configuration State Input Variables

  const [walletPost, setWalletPost] = useState("");
  const [inputRetweet, setInputRetweet] = useState("");
  const [inputLike, setInputLike] = useState("");
  const [inputContent, setInputContent] = useState("");
  const [inputTwitterHandle, setInputTwitterHandle] = useState("");
  const [finishTwitter, setFinishTwitter] = useState(false);

  // Summaries

  const [confirmGiveawaySummary, setConfirmGiveawaySummary] = useState(false);
  const [finishLottery, setFinishLottery] = useState(false);

  const contextObj = {
    setGetStarted,
    start: [getStarted, setGetStarted],
    assetChoice: [assetChosen, setAssetChosen],
    distributionMethod: [chosenDistributionMethod, setChosenDistributionMethod],
    erc20: [erc20Token, setErc20Token],
    submit: [setErc20Submitted, setMaticSubmitted, setErc721Submitted],
    nfts: [nftsArray, setNftsArray],
    wallet: [address, setAddress],
    winners: [numOfWinners, setNumOfWinners],
    numTokens: [numOfTokens, setNumOfTokens],
    duration: [durationInHours, setDurationInHours],
    submitInfo: [setMaticInfoSubmit, setErc20InfoSubmit, setErc721InfoSubmit],
    submitInfoValue: [maticInfoSubmit, erc20InfoSubmit, erc721InfoSubmit],
    submitInfoSet: [
      setMaticInfoSubmit,
      setErc20InfoSubmit,
      setErc721InfoSubmit,
    ],
    finished: [finishTwitter, setFinishTwitter],
    setInputs: [
      setInputRetweet,
      setInputLike,
      setInputContent,
      setInputTwitterHandle,
      setWalletPost,
    ],
    summaryGiveaway: [confirmGiveawaySummary, setConfirmGiveawaySummary],
    totalPercReward: [totalPercentageReward, setTotalPercentageReward],
    twitterInputs: [
      inputRetweet,
      inputLike,
      inputContent,
      inputTwitterHandle,
      walletPost,
    ],
    finishTheLottery: [finishLottery, setFinishLottery],
    setTwitterConfig: [setRetweet, setLike, setContent, setTwitterHandle],
    authToken: [oauth_Token, set_OAuthToken],
    authVerifier: [oauth_Verifier, set_OAuthVerifier],
  };

  return (
    <div className="flex justify-center items-center h-full">
      <div className="w-full max-h-[420px] min-w-[300px] h-[590px] mx-[20px] sm:max-h-[480px] md:h-[490px] lg:min-h-[620px] max-w-[900px] rounded-[58px] bg-gradient-to-b from-[#332F47] via-[#322e52] to-[#332F47]">
        <LotteryContext.Provider value={contextObj}>
          {!getStarted && <StartingPage />}
          {getStarted &&
            !maticSubmitted &&
            !erc20Submitted &&
            !erc721Submitted && <SelectAssets />}
          {maticSubmitted &&
            !erc20Submitted &&
            !maticInfoSubmit &&
            !erc721InfoSubmit && <TokenSpecifics type={"matic"} />}
          {erc20Submitted &&
            !maticInfoSubmit &&
            !erc20InfoSubmit &&
            !erc721InfoSubmit && <TokenSpecifics type={"erc20"} />}
          {erc721Submitted &&
            !maticInfoSubmit &&
            !erc20InfoSubmit &&
            !erc721InfoSubmit && <ERC721Specifics />}
          {(maticInfoSubmit || erc20InfoSubmit || erc721InfoSubmit) &&
            !finishTwitter && (
              <TwitterConfiguration
                retweet={retweet}
                setRetweet={setRetweet}
                like={like}
                setLike={setLike}
                content={content}
                setContent={setContent}
                twitterHandle={twitterHandle}
                setTwitterHandle={setTwitterHandle}
                setInputRetweet={setInputRetweet}
                setInputLike={setInputLike}
                setInputContent={setInputContent}
                setInputTwitterHandle={setInputTwitterHandle}
                setFinishTwitter={setFinishTwitter}
                inputTwitterHandle={inputTwitterHandle}
                inputRetweet={inputRetweet}
                inputLike={inputLike}
                inputContent={inputContent}
                assetChosen={assetChosen}
                walletPost={walletPost}
                setWalletPost={setWalletPost}
              />
            )}
          {finishTwitter && !confirmGiveawaySummary && (
            <GiveawaySummary
              setRetweet={setRetweet}
              setLike={setLike}
              setContent={setContent}
              setTwitterHandle={setTwitterHandle}
              setConfirmGiveawaySummary={setConfirmGiveawaySummary}
            />
          )}
          {finishTwitter && confirmGiveawaySummary && !finishLottery && (
            <TwitterSummary
              walletPost={walletPost}
              inputRetweet={inputRetweet}
              retweet={retweet}
              like={like}
              content={content}
              twitterHandle={twitterHandle}
              inputTwitterHandle={inputTwitterHandle}
              inputLike={inputLike}
              inputContent={inputContent}
            />
          )}
          {finishLottery && <FinishScreen />}
        </LotteryContext.Provider>
      </div>
      {finishLottery && <Confetti />}
    </div>
  );
}

export default LotteryBox;
