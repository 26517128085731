import React from "react";

function DzinButtons({
  content,
  setFunc,
  funcValue,
  setThinking,
  setThinkingStep,
}) {
  const handleClick = (value) => {
    setThinking(true);
    setTimeout(() => {
      setThinking(false);
      setThinkingStep((prev) => prev + 1);
    }, 2000);
    setFunc(value);
  };
  const buttons = content.map((button, idx) => (
    <button
      key={idx}
      onClick={() => handleClick(button)}
      className="cursor-pointer bg-[#5B58A6] rounded-2xl text-center w-[165px] h-[38px] sm:w-[190px] sm:h-[45px] lg:w-[220px] lg:h-[55px]"
    >
      {button}
    </button>
  ));
  const disabledButtons = content.map((button, idx) => (
    <button
      key={idx}
      className={`cursor-not-allowed ${
        funcValue === button ? "bg-[#5b8f43]" : "bg-[#5B58A6]"
      }  rounded-2xl text-center w-[165px] h-[38px] sm:w-[190px] sm:h-[45px] lg:w-[220px] lg:h-[55px]`}
    >
      {button}
    </button>
  ));
  return (
    <>
      {funcValue === "" && (
        <div className="font-semibold font-inter flex max-w-[60%] flex-wrap gap-[10px] xxs370:text-[13px] sm:text-[14px] lg:text-[16px] text-[12px] ml-[85px] sm:ml-[125px] md:ml-[145px] xxs370:ml-[105px]">
          {buttons}
        </div>
      )}
      {funcValue !== "" && (
        <div className="font-semibold font-inter flex max-w-[60%] flex-wrap gap-[10px] xxs370:text-[13px] sm:text-[14px] lg:text-[16px] text-[12px] ml-[85px] sm:ml-[125px] md:ml-[145px] xxs370:ml-[105px]">
          {disabledButtons}
        </div>
      )}
    </>
  );
}

export default DzinButtons;
