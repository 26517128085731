import React, { useState } from "react";

function NFTCard({ image, nft, updatePickedNfts, chosenNfts }) {
  const [selected, setSelected] = useState(false);

  let displayedNumber =
    chosenNfts.findIndex(
      (item) => item.tokenUri.gateway === nft[0].tokenUri.gateway
    ) + 1;

  const handleClick = (nft) => {
    if (displayedNumber !== 0) {
      setSelected((prev) => !prev);
      updatePickedNfts(nft[0]);
    }
    if (displayedNumber === 0 && chosenNfts.length < 5) {
      setSelected((prev) => !prev);
      updatePickedNfts(nft[0]);
    }
  };

  return (
    <div
      className={`w-[80px] h-[80px] lg:w-[160px] lg:h-[160px] relative ${
        !selected && chosenNfts.length === 5
          ? "cursor-not-allowed"
          : "cursor-pointer"
      }`}
    >
      <img
        onClick={() => handleClick(nft)}
        className={`w-full h-full object-fill ${selected ? "blur-sm" : ""}`}
        src={image}
        alt={nft[0].rawMetadata.name}
      ></img>
      <p className="absolute top-[5%] left-[30%] lg:top-[24%] lg:left-[40%] text-[#c6c9ce] text-[54px] ">
        {displayedNumber === 0 ? "" : displayedNumber}
      </p>
    </div>
  );
}

export default NFTCard;
