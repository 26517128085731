import React, { useRef, useContext } from "react";
import { LotteryContext } from "../main/LotteryBox";

import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

function NumOfWinners() {
  const { winners } = useContext(LotteryContext);
  const winnersRef = useRef(null);

  const [numOfWinners, setNumOfWinners] = winners;

  const handleWinnersChange = (type, input) => {
    if (input === "winners") {
      if (
        parseInt(winnersRef.current.value) !== 1 &&
        type === "dec" &&
        parseInt(winnersRef.current.value) !== 0
      ) {
        if (numOfWinners === "") {
          setNumOfWinners(1);
          winnersRef.current.value = 1;
        } else {
          winnersRef.current.value = parseInt(winnersRef.current.value) - 1;
        }
      }
      if (type === "inc") {
        if (numOfWinners === "") {
          setNumOfWinners(1);
          winnersRef.current.value = 1;
        } else {
          winnersRef.current.value = parseInt(winnersRef.current.value) + 1;
        }
      }
      setNumOfWinners(parseInt(winnersRef.current.value));
    }
  };

  const handleInputChange = (event) => {
    winnersRef.current.value = winnersRef.current.value.replace(/[^\d]/g, "");
    setNumOfWinners(event.target.value);
  };

  return (
    <div className={`flex flex-col gap-[1em] items-center text-center`}>
      <p className="text-[#A49D9D] font-semibold lg:w-[100px] w-[70px] md:w-[80px] text-[12px] md:text-[14px] lg:text-[16px]">
        Number of winners
      </p>
      <div className="bg-[#393354] w-[90px] md:w-[100px] lg:w-[120px] md:h-[30px] h-[20px] lg:h-[40px] rounded-3xl flex items-center justify-between p-[15px]">
        <AiOutlineMinus
          className="text-[#796FA8] text-[16px] md:text-[18px] lg:text-[20px] cursor-pointer"
          onClick={() => handleWinnersChange("dec", "winners")}
        />
        <input
          ref={winnersRef}
          defaultValue={1}
          onChange={(e) => handleInputChange(e)}
          className="text-[#A49D9D] font-semibold bg-transparent w-[30px] outline-none text-center lg:text-[16px] md:text-[14px] text-[12px]"
        />
        <AiOutlinePlus
          className="text-[#796FA8] text-[16px] md:text-[18px] lg:text-[20px] cursor-pointer"
          onClick={() => handleWinnersChange("inc", "winners")}
        />
      </div>
    </div>
  );
}

export default NumOfWinners;
