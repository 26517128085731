import React, { useContext } from "react";
import { VscDebugRestart } from "react-icons/vsc";
import { TbArrowBackUp } from "react-icons/tb";
import { FormContext } from "../main/ContractForm";

function RightButtons({}) {
  const {
    creatorStart,
    chosenService,
    isThinking,
    stepThinking,
    agreementService,
    isInformedByEmail,
    emailAddress,
    currStep,
  } = useContext(FormContext);

  const [creatorStarted, setCreatorStarted] = creatorStart;
  const [serviceChoice, setServiceChoice] = chosenService;
  const [thinking, setThinking] = isThinking;
  const [thinkingStep, setThinkingStep] = stepThinking;
  const [serviceAgreement, setServiceAgreement] = agreementService;
  const [informEmail, setInformEmail] = isInformedByEmail;
  const [email, setEmail] = emailAddress;
  const [currentStep, setCurrentStep] = currStep;

  const handleGoBack = () => {
    if (currentStep === "Service Agreement") {
      setServiceChoice("");
      setThinkingStep(1);
    } else if (currentStep === "Inform Email") {
      setServiceAgreement("");
      setThinkingStep(2);
    } else if (currentStep === "Finished no email") {
      setInformEmail("");
      setThinkingStep(3);
    } else if (currentStep === "Inputting email") {
      setInformEmail("");
      setThinkingStep(3);
    } else if (currentStep === "Finished with email") {
      setEmail("");
      setThinkingStep(4);
    }
  };

  const handleRestart = () => {
    setCreatorStarted(false);
    setServiceChoice("");
    setThinking(false);
    setThinkingStep(1);
    setServiceAgreement("");
    setInformEmail("");
    setEmail("");
    setCurrentStep("Choose Service");
  };

  return (
    <div className="flex gap-[20px] py-[20px]">
      {!thinking && (
        <div
          onClick={() => handleRestart()}
          className="flex gap-[10px] items-center text-[#B9B6B6] cursor-pointer"
        >
          <p className="text-[18px] font-semibold font-inter">Restart</p>
          <VscDebugRestart className="text-[24px]" />
        </div>
      )}
      {thinking && (
        <div className="flex gap-[10px] items-center text-[#B9B6B6] cursor-not-allowed">
          <p className="text-[18px] font-semibold font-inter">Restart</p>
          <VscDebugRestart className="text-[24px]" />
        </div>
      )}

      {!thinking && (
        <div
          onClick={() => handleGoBack()}
          className="flex gap-[10px] items-center text-[#B9B6B6] cursor-pointer"
        >
          <p className="text-[18px] font-semibold font-inter">Go back</p>
          <TbArrowBackUp className="text-[29px]" />
        </div>
      )}
      {thinking && (
        <div className="flex gap-[10px] items-center text-[#B9B6B6] cursor-not-allowed">
          <p className="text-[18px] font-semibold font-inter">Go back</p>
          <TbArrowBackUp className="text-[29px]" />
        </div>
      )}
    </div>
  );
}

export default RightButtons;
