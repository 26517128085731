import React, { useContext, useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { toast } from "react-toastify";
import { LotteryContext } from "./LotteryBox";
import axios from "axios";
import MoonLoader from "react-spinners/MoonLoader";

function StartingPage() {
  const { status, chainId } = useMetaMask();
  const { setGetStarted, authToken, authVerifier } = useContext(LotteryContext);

  const [oauth_Token, setOAuth_Token] = authToken;
  const [oauth_Verifier, setOAuth_Verifier] = authVerifier;

  const [urlPromiseActive, setUrlPromiseActive] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const oauthToken = searchParams.get("oauth_token");
    const oauthVerifier = searchParams.get("oauth_verifier");
    setOAuth_Token(oauthToken);
    setOAuth_Verifier(oauthVerifier);
  }, [setOAuth_Token, setOAuth_Verifier]);

  const fetchUrl = async () => {
    const url = await axios.get(
      "https://baseapi.vaiot.ai/api/v1/twitter/generateAuthLink",
      { withCredentials: true }
    );
    return url.data.authLink;
  };

  const redirectTwitter = async () => {
    setUrlPromiseActive(true);
    const url = await fetchUrl();
    setUrlPromiseActive(false);

    window.location.href = url;
  };

  const handleStart = async () => {
    if (status === "notConnected") {
      toast.error("Please connect your wallet first!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    if (
      process.env.REACT_APP_DEV_ENV === "production" &&
      chainId !== "0x1" &&
      chainId !== "0x89" &&
      status === "connected"
    ) {
      toast.error("Please switch your network!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    if (
      process.env.REACT_APP_DEV_ENV === "development" &&
      chainId !== "0x5" &&
      chainId !== "0x13881" &&
      status === "connected"
    ) {
      toast.error("Please switch your network!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    if (
      process.env.REACT_APP_DEV_ENV === "production" &&
      (chainId === "0x1" || chainId === "0x89") &&
      status === "connected"
    ) {
      const data = await axios.post(
        "https://baseapi.vaiot.ai/api/v1/lottery/setUserTokens",
        {
          oauth_token: oauth_Token,
          oauth_verifier: oauth_Verifier,
        },
        { withCredentials: true }
      );
      setGetStarted(true);
    }
    if (
      process.env.REACT_APP_DEV_ENV === "development" &&
      (chainId === "0x5" || chainId === "0x13881") &&
      status === "connected"
    ) {
      const data = await axios.post(
        "https://baseapi.vaiot.ai/api/v1/lottery/setUserTokens",
        {
          oauth_token: oauth_Token,
          oauth_verifier: oauth_Verifier,
        },
        { withCredentials: true }
      );
      setGetStarted(true);
    }
  };

  return (
    <div className="w-full flex flex-col items-center">
      <h1 className="text-white w-[280px] text-[22px] sm:text-[24px] md:text-[26px] lg:text-[28px]  sm500:w-[340px] sm:w-[380px] md:w-[400px] md:mb-[2em] lg:w-[500px] font-inter font-semibold text-center mt-[2em] mb-[1em] lg:mt-[4em] lg:mb-[2em]">
        Create your own, automated Giveaway Contract
      </h1>
      <p className="font-inter text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px] text-center mx-[3em] sm:mx-[10em] sm500:mx-[8em] md:mx-[10em]">
        A transparent, decentralized way to distribute rewards as part of your
        incentivized marketing campaigns
      </p>
      <div className="mt-[4em] sm:mt-[8em] sm500:mt-[6em]">
        {oauth_Token !== null &&
          oauth_Verifier !== null &&
          !urlPromiseActive && (
            <button
              onClick={() => handleStart()}
              className="hover:text-[#b6abf4] hover:from-[#4a3f8b] hover:to-[#4230aa] hover:via-[#6054a3] text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px] font-semibold font-inter text-[#BDB2FF] bg-gradient-to-b from-[#493D8D] via-[#493D8D] to-[#311da3] py-[6px] px-[30px]  sm:px-[35px] md:py-[8px] md:px-[40px] lg:py-[10px] lg:px-[50px] rounded-md"
            >
              Get Started
            </button>
          )}
        {urlPromiseActive && <MoonLoader size={32} color={"#ffff"} />}
        {oauth_Token === null &&
          oauth_Verifier === null &&
          !urlPromiseActive && (
            <button
              onClick={() => redirectTwitter()}
              className="hover:text-[#b6abf4] hover:from-[#4a3f8b] hover:to-[#4230aa] hover:via-[#6054a3] text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px] font-semibold font-inter text-[#BDB2FF] bg-gradient-to-b from-[#493D8D] via-[#493D8D] to-[#311da3] py-[6px] px-[30px]  sm:px-[35px] md:py-[8px] md:px-[40px] lg:py-[10px] lg:px-[50px] rounded-md"
            >
              Connect Twitter
            </button>
          )}
      </div>
    </div>
  );
}

export default StartingPage;
