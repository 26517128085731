import React, { useContext } from "react";
import TopContainer from "../parts/TopContainer";
import TwitterCheckBox from "../parts/TwitterCheckbox";
import TwitterInput from "../parts/TwitterInput";
import { toast } from "react-toastify";
import { useMetaMask } from "metamask-react";
import axios from "axios";
import { LotteryContext } from "./LotteryBox";

function TwitterConfiguration({
  retweet,
  setRetweet,
  like,
  setLike,
  content,
  setContent,
  twitterHandle,
  setTwitterHandle,
  setInputRetweet,
  setInputLike,
  setInputContent,
  setInputTwitterHandle,
  setFinishTwitter,
  inputTwitterHandle,
  inputRetweet,
  inputLike,
  inputContent,
  assetChosen,
  walletPost,
  setWalletPost,
}) {
  let checkConditionAndThrowError = (condition, error) => {
    if (condition) {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const { chainId } = useMetaMask();

  const handleSubmit = async () => {
    const regexAddress =
      /^https?:\/\/(?:www\.)?twitter\.com\/\w+\/status\/\d+$/;

    const twitter_requirements = {
      retweet: inputRetweet,
      like: inputLike,
      follow: inputTwitterHandle,
      content: inputContent,
      wallet_post: walletPost,
    };

    const twitterVerification = await axios.post(
      "https://baseapi.vaiot.ai/api/v1/lottery/twitterRequirementsCheck",
      {
        twitter_requirements,
      },
      { withCredentials: true }
    );

    const error =
      twitterVerification.data !== "" ? twitterVerification.data.error : "";

    const errorMessage = {
      USER_BLACKLISTED: "User cannot have over 1 million followers!",
      USER_NOT_EXIST: "User does not exist!",
      TWEET_NOT_EXIST: "Tweet does not exist!",
      TWEET_OVER_1_DAY: "Tweets can't be older than 1 day!",
    };

    if (
      ((assetChosen === "ERC20" || assetChosen === "ERC721") &&
        process.env.REACT_APP_DEV_ENV === "production" &&
        chainId === "0x1") ||
      (((process.env.REACT_APP_DEV_ENV === "production" &&
        assetChosen === "MATIC" &&
        chainId === "0x89") ||
        (process.env.REACT_APP_DEV_ENV === "development" &&
          chainId === "0x5")) &&
        (assetChosen === "ERC20" || assetChosen === "ERC721")) ||
      (process.env.REACT_APP_DEV_ENV === "development" &&
        assetChosen === "MATIC" &&
        chainId === "0x13881")
    ) {
      if (
        retweet === "" &&
        like === "" &&
        content === "" &&
        twitterHandle === ""
      ) {
        checkConditionAndThrowError(
          inputRetweet === "" &&
            inputLike === "" &&
            inputContent === "" &&
            inputTwitterHandle === "",
          "Please check at least one box!"
        );
      } else if (walletPost === "") {
        checkConditionAndThrowError(
          walletPost === "",
          "Please input the url of the post where wallets will be collected!"
        );
      } else if (retweet && inputRetweet === "") {
        checkConditionAndThrowError(
          inputRetweet === "",
          "Please input the url of the post to retweet!"
        );
      } else if (like && inputLike === "") {
        checkConditionAndThrowError(
          inputLike === "",
          "Please input the url of the post to like!"
        );
      } else if (content && inputContent === "") {
        checkConditionAndThrowError(
          inputContent === "",
          "Please input the content of the tweet!"
        );
      } else if (twitterHandle && inputTwitterHandle === "") {
        checkConditionAndThrowError(
          inputTwitterHandle === "",
          "Please input your twitter handle!"
        );
      } else if (twitterHandle && inputTwitterHandle.charAt(0) !== "@") {
        checkConditionAndThrowError(
          inputTwitterHandle.charAt(0) !== "@",
          "Twitter handle should start with @!"
        );
      } else if (regexAddress.test(walletPost) !== true) {
        checkConditionAndThrowError(
          regexAddress.test(walletPost) !== true,
          "Make sure your twitter addresses are valid!"
        );
      } else if (retweet && regexAddress.test(inputRetweet) !== true) {
        checkConditionAndThrowError(
          regexAddress.test(inputRetweet) !== true,
          "Make sure your twitter addresses are valid!"
        );
      } else if (error !== "") {
        checkConditionAndThrowError(
          error !== "",
          `${twitterVerification.data.field} field: ${errorMessage[error]}`
        );
      } else if (like && regexAddress.test(inputLike) !== true) {
        checkConditionAndThrowError(
          regexAddress.test(inputLike) !== true,
          "Make sure your twitter addresses are valid!"
        );
      } else {
        setFinishTwitter(true);
      }
    } else {
      checkConditionAndThrowError(
        true,
        `Please switch your network to ${
          assetChosen === "MATIC" ? "Polygon" : "Ethereum"
        }!`
      );
    }
  };
  return (
    <div className="flex flex-col font-inter p-[30px] h-full justify-between gap-[2em] sm400:gap-[3em] sm500:gap-[0.8em] md:gap-[1em]">
      <div>
        <TopContainer
          setRetweet={setRetweet}
          setLike={setLike}
          setContent={setContent}
          setTwitterHandle={setTwitterHandle}
        />
        <div>
          <h2 className="font-inter text-[14px] sm500:text-[16px] sm:text-[20px] md:text-[22px] lg:text-[24px] font-bold text-[#EBE6E6] text-center">
            What are the requirements to join the giveaway?
          </h2>
          <div className="w-full flex flex-wrap font-inter font-semibold items-center justify-between mt-[.3em] md:mt-[1em] text-[#A49D9D]">
            <TwitterCheckBox setFunc={setRetweet} text={"Retweet"} />
            <TwitterCheckBox setFunc={setLike} text={"Like a post"} />
            <TwitterCheckBox setFunc={setContent} text={"Tweet something"} />
            <TwitterCheckBox setFunc={setTwitterHandle} text={"Follow"} />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <TwitterInput
            icon={"wallets"}
            setFunc={setWalletPost}
            placeholder={"Twitter post address to collect wallets"}
          />
          {retweet && (
            <TwitterInput
              icon={"retweet"}
              setFunc={setInputRetweet}
              placeholder={"Twitter post address to retweet"}
            />
          )}
          {like && (
            <TwitterInput
              icon={"like"}
              setFunc={setInputLike}
              placeholder={"Twitter post address to like"}
            />
          )}
          {content && (
            <TwitterInput
              icon={"content"}
              setFunc={setInputContent}
              placeholder={"Content of the tweet"}
            />
          )}
          {twitterHandle && (
            <TwitterInput
              icon={"follow"}
              setFunc={setInputTwitterHandle}
              placeholder={"Twitter handle to follow"}
              inputTwitterHandle={inputTwitterHandle}
            />
          )}
        </div>
      </div>

      <div
        className={`flex items-center justify-center sm500:mt-[2em] ${
          twitterHandle && content && like && retweet
            ? "mt-[2em] sm500:mt-[2em] md:mt-[3em] lg:mt-[4em]"
            : ""
        }`}
      >
        <button
          onClick={() => handleSubmit()}
          className="bg-[#5A5574] hover:text-[#d8d5d5] px-[32px] rounded-xl py-[12px] font-semibold text-[14px]"
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
}

export default TwitterConfiguration;
